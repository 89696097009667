import { useEffect, useState } from "react";

export function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  export  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  }

  // export function useResize() {
  //   const [width, setWidth] = useState(window.innerWidth);
  //   const [height, setHeight] = useState(window.innerHeight);

  //   const handleWindowSizeChange = useCallback(() => {
  //     setWidth(window.innerWidth);
  //     setHeight(window.innerHeight);
  //   }, [setWidth, setHeight]);

  //   useEffect(() => {
  //     window.addEventListener("resize", handleWindowSizeChange);
  //     return () => {
  //       window.removeEventListener("resize", handleWindowSizeChange);
  //     };
  //   }, [handleWindowSizeChange]);

  //   return [width, height];
  // }
