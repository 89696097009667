/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import Modal from 'components/Modal'
import { useState } from 'react';
import DeckActions from './DeckActions';
import DeckSelector from './DecksSelector';
import DeleteDeck from './DeleteDeck';
import NewDeck from './NewDeck'

const DecksComponent = () => {
    const [open, setOpen] = useState(false);
    const [openNew, setNew] = useState(false);
    const [openDelete, setDelete] = useState(false);

    const closeModal = () => {
        setOpen(false);
        setNew(false);
        setDelete(false);
    }
    const openNewDeck = () => {
        setNew(true);
        setOpen(true);
    }
    const openDeleteDeck = () => {
        setDelete(true);
        setOpen(true);
    }

    return <div css={css`justify-content:center; align-items:center; color:white; box-sizing:border-box; flex:1;`}>
        <Modal opened={open} handleClose={() => closeModal()}>
            {openNew && <NewDeck onClose={() => closeModal()} children={undefined} onClick={() => { }} />}
            {openDelete && <DeleteDeck onClose={() => closeModal()} children={undefined} onClick={() => { }} />}
        </Modal>
        <DeckSelector css={css`z-index:3;`} />
        <DeckActions 
         newDeck={() => openNewDeck() }
         deleteDeck={() =>  openDeleteDeck()}
        />
    </div>
}

export default DecksComponent