
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Alert from 'components/Alert';
import { Button } from 'components/Button';
import Card from 'components/Card';
import Modal from 'components/Modal';
import { motion } from 'framer-motion';
import { MouseEventHandler, useState } from 'react';
import { IconType } from 'react-icons';
import { FaPlus } from 'react-icons/fa';
import { useRecoilCallback, useRecoilValueLoadable } from 'recoil';
import { DeckRepository } from 'repositories/deck.repository';
import { cardsSelector, currentDeckAtom, currentDeckSelector } from 'store';

// 100 mock elements
// const mockCards = [...Array(100).keys()].map(i => (<div css={css`padding: 20px; background-color: blue;`}><img src={`https://robohash.org/card${i}`} css={css`max-width:100%;`} /></div>));

// grid layout
/*
const grid = css`
    display: inline-grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 20px;
    margin-top: 20px;
`;
*/
// 4 columns grid inline layout
const grid4 = css`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 20px;
    column-gap: 20px;
    @media screen and (min-width: 510px) {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
    @media screen and (min-width: 1080px) {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
`;


const CardList = () => {
    const cards = useRecoilValueLoadable(cardsSelector)
    if ([cards.state].includes("loading")) {
    return <div css={css`display:flex; justify-content:center; align-items:flex-start; height:100vh; width:100%;`}><h2>Loading cards...</h2></div>

    }
    if (cards.state === "hasError") {
        return <h2>Error {cards.contents}</h2>
    }

    const getCards = () => {
        if (cards.contents.length === 0) {
            return <div css={css`display:flex; justify-content:center; align-items:center; height:100vh; width:100%;`}><h2>No cards found</h2></div>
        }
        return cards.contents.map((card: any) => (<CardItem card={card} key={'cardList' + card.id} />))

    }

    return (
        <div css={css`display:flex; flex:6; 
        padding: 0 10px;
        @media screen and (min-width: 1080px) {
            padding: 0 20px;
        }
        `}>
            <div css={grid4}>
                {/* <AnimatePresence > */}

                     {getCards()}
                {/* </AnimatePresence> */}
            </div>
        </div>
    );
}


export const CardItem = ({ card, deckSelect }: any) => {
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [alertText, setAlertText] = useState('');
    const currentDeck = useRecoilValueLoadable(currentDeckSelector)
    const deck = currentDeck.contents;
    const addCard = useRecoilCallback(({ snapshot, set }) => async (card: any) => {
        if (!loading) {
            setLoading(true);
            const deck = snapshot.getLoadable(currentDeckSelector).contents;
            try{

                const result = await DeckRepository.updateDeck({ ...deck, cards: [...deck.cards, card] });
                set(currentDeckAtom, result.deck);
                setLoading(false);
            }catch (e) {
                const error = e as Error;
                setLoading(false);
                console.log(e);
                setAlertText(error.message);
            }
        }

    }, [])

    const removeCard = useRecoilCallback(({ snapshot, set }) => async (card: any) => {
        if (!loading) {
            setLoading(true);
            const deck = snapshot.getLoadable(currentDeckSelector).contents;
            const newDeck = { ...deck, cards: deck.cards.filter((c: any) => c.id !== card.id) }
            const result = await DeckRepository.updateDeck(newDeck);
            set(currentDeckAtom, result.deck);
            setLoading(false);
        }
    }, [])

    const isAdded = (currentDeck.state === 'hasValue') && deck?.cards.find((c: any) => c.id === card.id) !== undefined;


    const renderAdded = () => {
        if (!deckSelect && isAdded && !loading) {
            return <div css={css`position:absolute; top:0; width:100%; height:100%; display:flex; justify-content:center; align-items:center; background-color: #0C092D99;`}>added</div>
        }
    }

    const containerStyles = css`
                display:grid;
                justify-content:center;
                align-items: center;padding: 20px;
                /* background-color: blue; */
                transition: all 0.2s ease-in-out;
                position:relative;
                cursor:pointer;
                aspect-ratio: 1;
                @media screen and (min-width: 1080px) {
                    >#card-cta{
                        display: none;
                    }
                &:hover{
                    > #card-cta{
                        display: flex;
                        }
                    }
                
                }`;
    const handler: MouseEventHandler = (e) => {
        e.preventDefault()
        e.stopPropagation()
        !loading && addCard(card)
    }

    return (
        <motion.div  css={containerStyles} onClick={() => setShowModal(true)}
            whileHover={{ scale: 1.05 }}
        >
            <Card card={card} css={css`
                         @media screen and (max-width: 600px){
                             transform: scale(0.7);
                             margin: -50px 0;
                         }
                    `}/>
            <Alert  onClose={() => setAlertText('')} text={alertText} opened={alertText.length>0}/>
            <Modal handleClose={() => { setShowModal(false) }} opened={showModal} >
                <div css={css`display:flex; justify-content:space-evenly; align-items:center; height:100vh; width:100vh; max-width:400px; color:white; flex-direction: column; gap: 40px;`}>
                    <Button color='secondary' onClick={() => setShowModal(false)}>x</Button>
                    {/* <img src={`https://robohash.org/card${card.id}`} css={css`max-width:100%;`} onClick={() => { }} /> */}
                    <div css={css`
                        min-width:280px;
                        width:100%; 
                        transform: scale(1.5);
                        @media screen and (max-width: 400px) {
                        transform: scale(1.3);
                        max-width: 300px;
                    }`}>

                    <Card card={card} css={css`
                         margin: 0 auto;
                    `}/>
                    </div>
                    <>
                        {
                            deckSelect ? <div css={css`width:100%;height:50px;`} /> : (
                                <>
                                    {isAdded ?
                                        <Button onClick={() => {
                                            removeCard(card).then()
                                            setShowModal(false)
                                        }} color="danger">
                                            Remove from deck</Button> :
                                        <Button  onClick={() => {
                                            addCard(card).then()
                                            setShowModal(false)
                                        }} color='success'>
                                            Add To Deck</Button>}
                                </>
                            )
                        }
                    </>
                </div>
            </Modal>
            {loading && <div css={css`position:absolute;
                top:0;
                width:100%;
                height:100%;
                display:flex;
                justify-content:center;
                align-items:center;
                background-color: #0C092D99;`}>Loading</div>}
            {isAdded && renderAdded()}
            {!isAdded && !loading &&   <Button variant='icon' color="success" css={css`width:fit-content;
                position: absolute;
                right:0;
                @media screen and (min-width: 600px) {
                    right:3%;
                   }
                @media screen and (min-width: 1080px) {
                    right:0;
                   }
                @media screen and (max-width: 600px) {
                    right:-7%;
                   }
                
                `} onClick={handler} id="card-cta" icon={
                <FaPlus/> as unknown as IconType}
            />}
        </motion.div>
    );
}
export default CardList;
