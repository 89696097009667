import axios from 'axios';
import {
    ValidateIfTokenIsPresent,
    ValidateIfUserIsPresent
} from "../infraestructure/repositories/sessionVerifier.repository";
import {getHost} from "../infraestructure/services/host-consumer/host-consumer.service";

export const CardRepository = {
    async getCards(filters: any) {
        const {sortBy} = filters;
        const sort = sortBy.attribute !== '' ? `&sortProperty=${sortBy.attribute}&sort=${sortBy.type}` : '';
        const filterString = getFiterString(filters.filters);
        const token = ValidateIfTokenIsPresent()
        const user = ValidateIfUserIsPresent()
        const response = await axios(`${getHost('deck')}/api/v1/deck-manager/cards/owner/${user}?${filterString}${sort}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });

        const results = await response.data;

        return results.cards;
    },

}

const getFiterString = (filters: any) => {
    let filterString = '';
    if(filters.collection?.length>0) {
        filterString += `&collection=${filters.collection}`
    }
    return filterString;
}
