import axios from 'axios';
import {
    ValidateIfTokenIsPresent,
    ValidateIfUserIsPresent
} from '../infraestructure/repositories/sessionVerifier.repository'
import {getHost} from "../infraestructure/services/host-consumer/host-consumer.service";

export const DeckRepository = {
    async getDecks() {
        const token = ValidateIfTokenIsPresent()
        const user = ValidateIfUserIsPresent()
        const response = await axios(`${getHost('deck')}/api/v1/deck-manager/user/${user}/decks`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });

        const results = await response.data;
        return results.decks;
    },
    async createDeck(name: string) {
        const token = ValidateIfTokenIsPresent()
        const user = ValidateIfUserIsPresent()
        const response = await axios.post(`${getHost('deck')}/api/v1/deck-manager/user/${user}/decks`,
            {
                "name": name,
                "creator": user,
                "owner": user,
                "description": "",
                "cards": []
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
        return await response.data;
    },
    async deleteDeck(id: string) {
        const token = ValidateIfTokenIsPresent()
        const user = ValidateIfUserIsPresent()
        const response = await axios.delete(`${getHost('deck')}/api/v1/deck-manager/user/${user}/decks/${id}`,{
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });
        return await response.data;
    },
    async updateDeck(deck:any) {
        //TODO user id
        const token = ValidateIfTokenIsPresent()
        const user = ValidateIfUserIsPresent()
        const response = await axios.patch(`${getHost('deck')}/api/v1/deck-manager/user/${user}/decks/${deck.id}`,
            deck,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
        return await response.data;
    }

}

