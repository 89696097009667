/** @jsxImportSource @emotion/react */
import Layout from "components/Layout"
import { css } from '@emotion/react';
import { useRecoilValueLoadable } from "recoil";
import {currentDeckSelector, decksSelector} from "store";
import React, {useEffect} from "react";
import titleImage from "../assets/images/deck-title.png";
import {CardItem} from "../components/DeckBuilder/CardList";
import DeckSelector from "../components/DeckBuilder/DecksSelector";
import styled from "@emotion/styled";
import {Button} from "../components/Button";
import {ConfigService} from "../config.service";

export default function DeckSelect(){
    const decks = useRecoilValueLoadable(decksSelector)
    const renderNodata = () => {
        if (decks.state === "loading") {
            return <div css={css`display:flex; justify-content:center; align-items:center; height:100vh;`}><h2>Loading decks...</h2></div>
        }
        if (decks.state === "hasError") {
            return <div>Error</div>
        }
        if (decks.state === "hasValue" && decks.contents.length === 0) {
            return <div>No Decks</div>
        }
        return null
    }

    useEffect(()=>{
        ConfigService.checkWallet().then()
    },[])

    const mainContainer = css`
      display:flex;
      flex:1;
      width:100%;
      max-width: 1100px;
      margin: 40px auto 30px;
      color: white;
      flex-direction:column;
      align-items:center;
    `

    const container = css`
      width: 100%;
      filter: drop-shadow(0px -3px 3px #F651DC) drop-shadow(0px -4px 15px rgba(246, 19, 182, 0.8));
    `

    const containerInner = css`
      max-width: 1100px;
      width: 100%;
      min-height: 100px;
      display: flex;
      background-color: rgba(17, 18, 62, 1);
      flex-direction: column;
      clip-path: polygon(calc(100% - 50px) 0,100% 50px,100% calc(100% - 50px),calc(100% - 50px) 100%,0 100%,0 50px,50px 0);
    `

    const titleContainer = css`
      position: absolute;
      left: 50%;
      top: -20px;
      transform: translateX(-50%);
      width: fit-content;
      z-index: 2;
      display: flex;
      justify-content: center;
      font-family: North Zone,serif;
      filter: drop-shadow(0px 2px 2px #000);
    `

    const containerInnerInner = css`
      padding: 15px;
      max-width: 1100px;
      width: 100%;
      min-height: 100px;
      background: linear-gradient(180deg, rgba(225, 132, 223, 0.5) 0%, rgba(246, 15, 242, 0) 16.93%);
    `

    const grid4 = css`
      width: calc(100% - 30px);
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      grid-gap: 20px;
      column-gap: 20px;
      @media screen and (min-width: 510px) {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      }
      @media screen and (min-width: 1080px) {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      }
    `;

    const deckSelectContainer = css`
      width: 100%;
      margin: 0 0 50px 0;
      min-height: 37px;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      grid-gap: 20px;
      column-gap: 20px;
      @media screen and (min-width: 510px) {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      }
      @media screen and (min-width: 1080px) {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      }
    `

    const title = css`
      position:absolute;
      top:45%;
      transform:translateY(-50%);
      text-overflow: ellipsis;
      white-space:nowrap;
    `

    const BigJune = styled.span`
      font-family: 'Junegull',serif;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 18px;
      /* identical to box height, or 75% */
      display: flex;
      align-items: center;
      letter-spacing: -0.02em;
      text-transform: uppercase;
    `

    const currentDeck = useRecoilValueLoadable(currentDeckSelector)
    const deckNoData = () => {
        let error = ''
        if (currentDeck.state === "loading") {
            error = 'Loading decks...'
        }
        if (currentDeck.state === "hasError") {
            error = 'Error'
        }
        if (currentDeck.state === "hasValue" && !currentDeck.contents.cards) {
            error = 'No Deck'
        }
        if(error !== ''){
            return <div css={containerInner}>
                <div css={containerInnerInner}>
                    <div css={css`display:flex; justify-content:center; align-items:center; height:50vh;`}><h2>{error}</h2></div>
                </div>
            </div>
        }
        return null
    }

    ConfigService.playURL = `/play/?deck-id=${currentDeck.contents.id}`
    function goTo(type:'play'|'tutorial'){
        let url = '/play/'
        if(type === 'play') url += `?deck-id=${currentDeck.contents.id}`
        else url += '?tutorial=1'
        window.open(url,'_self')
    }

    return (
        <Layout>
            {renderNodata() || (
                <div css={mainContainer}>
                    <div css={deckSelectContainer}>
                        <div css={css`min-height:37px;margin-bottom:20px`}>
                            <DeckSelector css={css`z-index:3;`} />
                        </div>
                        <div css={css`display:flex;flex-direction: row;align-items: center;margin-bottom:20px;padding-left:10px`}>
                            <BigJune>{currentDeck.contents.totalLinkPoints || 0}</BigJune>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <span>Link Points</span>
                        </div>
                        <div css={css`min-height:37px;margin-bottom:20px`}>
                            <Button css={css`max-height:43px; font-size:12px; margin: auto 0; `} color="secondary" onClick={()=>goTo('tutorial')}>
                                Tutorial
                            </Button>
                        </div>
                        <div css={css`min-height:37px;margin-bottom:20px`}>
                            <Button css={css`max-height:43px; font-size:12px; margin: auto 0; `} color="secondary" onClick={()=>goTo('play')}>
                                Play
                            </Button>
                        </div>
                    </div>
                    <div css={container}>
                        {deckNoData() || (
                            <>
                                <div css={titleContainer}>
                                    <img alt="title" src={titleImage} css={css`height:35px;  `}/>
                                    <span css={title}>{currentDeck.contents.name}</span>
                                </div>
                                <div css={containerInner}>
                                    <div css={containerInnerInner}>
                                        <div css={grid4}>
                                            {
                                                currentDeck.contents.cards.map((card:any)=>(
                                                    <CardItem key={card.id} card={card} deckSelect={true} />
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
        </Layout>

    )
}
