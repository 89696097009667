/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import craftingImage from '../assets/images/sections/crafting.png'
import { HeroContainer, HeroSection } from './Hero';
import { GradientHeading } from './Hero';
import  { useInView} from 'react-intersection-observer'
import { useEffect } from 'react';
import { useAnimation, motion } from 'framer-motion';

const Crafting = () => {
    const [ref, inView] = useInView({
        threshold: 0.5,
    })
    const leftAnimation = useAnimation();
    const rightAnimation = useAnimation();

    useEffect(() => {
        if(inView){
            leftAnimation.start({
                opacity: 1,
                x: 0,
                transition: {
                    duration: .5,
                    ease: 'easeOut'
                }
            }).then()
            rightAnimation.start({
                opacity: 1,
                y: 0,
                transition: {
                    duration: .5,
                    ease: 'easeOut'
                }
            }).then()
        } else {
            leftAnimation.start({
                opacity: 0,
                x: -100,
                transition: {
                    duration: .5,
                    ease: 'easeOut'
                }
            }).then()
            rightAnimation.start({
                opacity: 0,
                y: 100,
                transition: {
                    duration: .5,
                    ease: 'easeOut',
                    delay: .25
                }
            }).then()
        }
    },[inView,leftAnimation,rightAnimation])




    return <HeroContainer ref={ref} id="crafting"
    css={css`
        @media screen and (min-width: 1444px) {
            
            height:100vh;
        }
        `}>
        <HeroSection>
            <motion.div css={css`
            flex:1;
            justify-content: center;
            align-items: center;
            display:flex;

          `} animate={leftAnimation}>

                <div css={css`
             justify-content: center;
            align-items: flex-start;
            margin: auto;
            display: flex;
            flex-direction: column;
            gap: 20px; 
            max-width: 70%;
            `}>


                    <GradientHeading text="get creative with" />
                    <h1 css={css`
                        margin:0 auto;
                        @media screen and (min-width: 1444px) {
                            margin:0;
                        }
            `}>
                        crafting!
                    </h1>
                    <h3 css={css`
                margin-top:39px;
                margin-bottom:54px;
                @media screen and (min-width: 600px) {
                    font-size: 24px;
                }

            `}>Use NFTs and our native token $CHN to create new playable and cosmetic NFTs.
                    </h3>
                    <div css={css`
                display: flex;
                width: 100%;
                justify-content: center;
                @media (min-width: 1400px) {
                    justify-content:space-between;
                }
            `}>
{/*
                        <Button css={{ width: 'fit-content' }}>
                            learn crafting
                        </Button> */}
                    </div>
                </div>
            </motion.div>
            <motion.div css={{ flex: 1, display: 'flex', minHeight: 350 }} animate={rightAnimation}>
                <img alt={'crafting'} src={craftingImage} css={css`max-width:100%; margin:auto; max-height:100%;`} />
            </motion.div>
        </HeroSection>
    </HeroContainer>
}
export default Crafting;

