/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { MouseEventHandler } from 'react';
import { IconType } from 'react-icons';
import Color from 'color';

// export const Button = () => {
//     return <div css={
//         css`display:flex;
//         img{
//             height:87px;

//         }
//         img.svgfix rect{
//             shape-renderer: crispEdges;
//         }
//         `}>
//         <img src={left} className="svgfix"/><span css={css`
//         display: flex;
//         flex-direction: row;
//         align-items: center;
//         padding: 16px 30px 25px;
//         gap: 10px;
//         width: 285px;
//         background: linear-gradient(180deg, #FF9FC8 0%, rgba(255, 159, 200, 0) 100%), #FF00F9;
//         font-family: 'Junegull';
//         font-style: normal;
//         font-weight: 400;
//         font-size: 21px;
//         line-height: 28px;
// /* identical to box height, or 133% */

// display: flex;
// align-items: center;
// letter-spacing: 0.03em;
// text-transform: uppercase;

// color: #F5F5F5;

// text-shadow: 2px solid black;
// -webkit-text-stroke: 2px black;
//     `}>aaa</span><img className="svgfix" src={right} /></div>
// }
type color = 'primary' | 'secondary' | 'success' | 'danger';
type variant = 'normal' | 'icon'
type ButtonType = { id?: string, children?: any, color?: color, variant?: variant, icon?: IconType, className?: string, href?:string, target?:string, onClick?: MouseEventHandler | undefined, disabled?:boolean }
const getColor = (props: any) => {
    switch (props.color) {
        case 'primary': return props.theme.color.primary;
        case 'secondary': return props.theme.color.secondary;
        case 'success': return props.theme.color.success;
        case 'danger': return props.theme.color.danger;
        default: return props.theme.color.primary;
    }
}
const Btn = styled.button <ButtonType>`
    width: ${props => props.variant === 'icon' ? '60px' : '100%'};
    padding: 20px;
    height: 60px;
    background: ${props => getColor(props)};
    &:hover{
        outline:2px solid white;
    }
    &:focus{
        outline: none;
        text-shadow: 0 3px 10px #f5f5f5;
        box-shadow: 0 3px 10px #f5f5f5;
    }
`

// noinspection JSUnusedGlobalSymbols
export const FlatButton = ({ children, color, variant, icon, className, onClick, disabled, href, target }: ButtonType) => {

    return <Btn color={color} variant={variant} icon={icon} className={className} onClick={onClick} disabled={disabled} href={href} target={target}>
        <>
            {icon}
            {children}
        </>
    </Btn>
}


export const Button = ({ children, color, icon, className, onClick, disabled=false, id, href, target }: ButtonType) => {
    const theme = useTheme();
    const mainColor = Color(getColor({ color, theme }))
    const brightestColor = mainColor.lighten(0.6)
    const lightColor = mainColor.lighten(.5)
    const lightShadow = mainColor.darken(.4)
    const darkShadow = mainColor.darken(.5)


    const Container = styled.div<{disabled:boolean}>`
        font-family: 'Junegull',serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        text-transform: uppercase;
        ${props => !props.disabled && css` filter:drop-shadow(-1px 6px 3px rgba(50, 50, 50, 0.5)) drop-shadow(0 -8px 7px ${lightColor.fade(0.4).string()}) drop-shadow(0 -3px 1px  ${lightShadow.fade(0.8).string()});`}
        will-change: filter;
        transition: ${props => !props.disabled ? `filter .1s ease-out` : 'none'};
        height:60px;
        min-width: 66px;
        display: flex;
        justify-content: center;
        align-items: center;

    :hover{
        ${props => !props.disabled && css`filter: drop-shadow(-1px 6px 3px rgba(50, 50, 50, 0.5)) drop-shadow(0 -15px 10px ${lightColor.fade(0.2).string()}) drop-shadow(0 -5px 3px ${lightShadow.fade(0.8).string()});`}

    }
    &:active{
         ${props => !props.disabled && css`transform:translateY(5px);`}
        a{
            /* background: linear-gradient(0deg, ${brightestColor.hsl().string()} 0%, ${lightColor.string()} 100%); ; */
            border-width: 5px;
            :before{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: -20px;
            right: 0;
            clip-path: polygon(0% 0%, 100% 0%, 100% calc(50% - 3px), calc(100% - 15px) 5px, 15px 5px, 0% calc(50% - 3px));
            background: ${brightestColor.string()};
        }
        }
    }
    a{
      text-decoration: none;
        transition: ${props => !props.disabled ? `filter .1s ease-out` : 'none'};
        letter-spacing: 1.5px;
        padding: 0 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        cursor: pointer;
        text-align: center;
        background: ${() =>  `linear-gradient(0deg, ${ disabled ?  mainColor.desaturate(.8).darken(0.7).string() : mainColor.hsl().string()} 0%, ${disabled ? lightColor.desaturate(.8).darken(0.7).string() : lightColor.string()} 100%)`};
        /* padding: 15px 40%; */
        clip-path: polygon(15px 0%, calc(100% - 15px) 0%, 100% calc(50% - 5px), 100% calc(50% + 5px), calc(100% - 15px) 100%, 15px 100%, 0% calc(50% + 5px), 0% calc(50% - 5px));
        position: relative;
        border-bottom: 10px solid ${lightShadow.string()};
        color:  ${props => !props.disabled ? 'white': 'rgba(63, 63, 63, 1)'};
        font-family: 'Varela Round', cursive;
        font-weight: 600;
        word-spacing: 1.3pt;
        line-height: 1.1;
        ${props => !props.disabled && css`text-shadow: 1px 0 0 ${darkShadow.string()}, -1px 0 0 ${darkShadow.string()}, 0 1px 0 ${darkShadow.string()}, 0 -1px 0 ${darkShadow.string()}, 1px 1px ${darkShadow.string()}, -1px -1px 0 ${darkShadow.string()}, 1px -1px 0 ${darkShadow.string()}, -1px 1px 0 rgb(56, 113, 133);`}
        ${props => !props.disabled && css`box-shadow: 0 8px 10px 2px rgba(0,0,0,0.6);`}
        :before{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: -20px;
            right: 0;
            clip-path: polygon(0% 0%, 100% 0%, 100% calc(50% - 3px), calc(100% - 15px) 8px, 15px 8px, 0% calc(50% - 3px));
            background: ${brightestColor.string()};
        }
        :after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: -20px;
        right: 0;
        clip-path: polygon(0% calc(50% - 10px), 15px calc(100% - 20px), 15px calc(100% - 10px), calc(100% - 15px) calc(100% - 10px), calc(100% - 15px) calc(100% - 20px), 100% calc(50% - 10px), 100% 100%, 0 100%);
        background: ${darkShadow.string()};

    }
    ${props => props.disabled && css`
        filter:none;
        cursor: not-allowed;
        :hover{
            filter: drop-shadow(none) !important;
            box-shadow: none;
        }
        ::before, ::after{
            display: none;
        }
        border: none;
        a{
            :hover{
                filter: drop-shadow(none);
                box-shadow: none;
            }
            border: none;
        }
    `}
    }
`
    return <Container className={className} onClick={!disabled ? onClick : undefined} disabled={disabled} id={id}>
        <a href={href} target={target}>
            {icon && <div css={css`
                filter: drop-shadow(2px 4px 6px ${darkShadow.string()});
                transform: translateY(5px);
                width: auto;
                height: auto;
            `}>

                {icon as any}
            </div>
            }
            {children && <div css={css`padding: 0 20px;`} >
                {children}
            </div>
            }
        </a>
    </Container>
}
