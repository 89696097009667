/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import pinkChevron from '../assets/svg/chevron-down-pink.svg'
import heroBackground from '../assets/images/hero-bg.jpg'
import heroBackgroundPortrait from '../assets/images/hero-bg-portrait.jpeg'
import cards3 from '../assets/images/sections/hero.png'
import { Button } from './Button';
import { useWindowDimensions } from '../utils';
import {motion} from 'framer-motion'
import getConfig from "config.service";

export const HeroContainer = styled(motion.div)<{backgroundImage?: string}>`
    display: flex;
    /* height: 100vh; */
    width:100%;
    position: relative;
    ${props => props.backgroundImage && `background-image: url(${props.backgroundImage})`};    

    @media (min-width: 1440px) {
        height: calc(100vh -  94px);
        scroll-snap-align: start;

    }
`
export const HeroSection = styled.div<{revert?: boolean}>`
    display: flex;
    flex-direction: ${props => props.revert ? 'column-reverse' : 'column'};
    width: 100%;
    @media (min-width: 1440px) {
        flex-direction: row;
    }
`

export const GradientHeading = ({ text, className }: { text: string, className?: string }) => {
    // noinspection CssInvalidPropertyValue
    const headingStyles = css`
        box-sizing: border-box;
        font-family: North Zone,serif;
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 30px;
        display: flex;
        align-items: center;
        background: linear-gradient(116.1deg, rgba(255, 255, 255, 0.3) 17.34%, rgba(255, 255, 255, 0) 31.77%), linear-gradient(91.97deg, #16CCEC 3.18%, rgba(0, 163, 255, 0.55) 48.63%, rgba(212, 0, 207, 0) 97.33%), #FF6CFF;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        justify-content: center;
        width: fit-content;
        padding: 0 20px;
        @media (min-width: 600px) {
            font-size: 47.77px;
            line-height: 45px;
        }
    `

    const topStyles = css`
        position: absolute;
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    `

    const top = css`
        ${headingStyles}
        ${topStyles}
    `

    const bottomStyles = css`
        mix-blend-mode: hard-light;
        filter: blur(14px);
    `

    const bottom = css`
        ${headingStyles}
        ${bottomStyles}
    `
        const Container = styled.div`
        position: relative;
        padding: 0 5px;
        transform: rotate(-2.46deg);
        margin:0 auto;
        @media (min-width: 1444px) {
            margin: 0;
        }
    `

    return <Container className={className}>
        <h1 css={top}>{text}</h1>
        <h1 css={bottom}>{text}</h1>
    </Container>
}

const Hero = () => {

    const windowDimentions = useWindowDimensions()
    return <HeroContainer css={css`
        margin-top: -94px;
    `}>
        <img alt={'hero background'} css={{position:'absolute', top:0, width:'100%', height:'100%',zIndex:-1 }} src={windowDimentions.height < windowDimentions.width ? heroBackground : heroBackgroundPortrait} />
        <HeroSection>
        <motion.div css={css`
            flex:1;
            justify-content: center;
            align-items: center;
            display:flex;
            flex-direction: column;
            padding-top:100px;
          `}
          initial={{opacity:0, y:100}}
          animate={{opacity:1, y:0}}
          transition={{duration:.5, delay:.5}}

          >

            <div css={css`
             justify-content: center;
            align-items: flex-start;
            margin: auto;
            display: flex;
            flex-direction: column;
            gap: 20px; 
            `}>

                <GradientHeading text="get ready for" />
                <h1 css={css`
                word-break: break-word;
                margin:0;
                
                /* max-width: 500px; */
            `}>
                    CHAIN
                    REACTION
                </h1>
                <h3 css={css`
                margin-top:39px;
                margin-bottom:54px;
            `}>
                  Bring your Collection and get your enemies REKT!
                </h3>
                <div css={css`
                display: flex;
                justify-content:center;
                align-items: center;
                width: fit-content;
                flex-direction: column;
                align-self: center;
                gap:20px;
                @media (min-width: 600px) {
                    flex-direction: row;
                    width: 100%;
                    justify-content: space-between;
                    button {width: fit-content;}
                }
            `}>
                     <Button href={getConfig().litePaperURL} target={'_blank'} color="secondary">Litepaper</Button>
                    {
                        <Button  onClick={() => {
                            window.open(getConfig().playURL, '_self')
                        }}>PLAY</Button>
                    }
                </div>
            </div>
        </motion.div>
        <motion.div css={{ flex: 1, justifyContent:'center', alignItems:'center', display:'flex', width:'100%'}}
        initial={{opacity:0, x:200}}
        animate={{opacity:1, x:0}}
        transition={{duration:.5, delay:.8}}
        >
            <img alt={'cards'} src={cards3} css={cardsImageStyles}/>
        </motion.div>
         </HeroSection>
        <ScrollHint />
    </HeroContainer>
}
export default Hero;
const cardsImageStyles = css`
    max-width: 80%;
    height: auto;
    aspect-ratio: 121/118;
    @media screen and (min-width: 1440px) {
        
    }
    
`
const ScrollHint = () => {
    return <div css={css`
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;
        font-family: Varela,serif;
        color:rgba(64, 210, 235, 1);
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 16px;
        display: none;
        @media screen and (min-width: 1440px) {
            display: block;
        }

    `}>
        <div css={css`
            display: flex;
            flex-direction:column;
            width:fit-content;
            text-align: center;
            margin: 0 auto;
        `}>
            Scroll to discover
            <img alt={'pink chevron'} src={pinkChevron} css={{ width: 52, margin: '10px auto' }} />
        </div>
    </div>
}
