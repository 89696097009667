/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import levelup from '../assets/images/sections/levelup.png'
import { HeroContainer, HeroSection } from './Hero';
import { GradientHeading } from './Hero';
import  { useInView} from 'react-intersection-observer'
import { useEffect } from 'react';
import { useAnimation, motion } from 'framer-motion';
const LevelUp = () => {
    const [ref, inView] = useInView({
        threshold: 0.5,
    })

    const leftAnimation = useAnimation();
    const rightAnimation = useAnimation();

    useEffect(() => {
        if(inView){
            leftAnimation.start({
                opacity: 1,
                x: 0,
                transition: {
                    duration: .5,
                    ease: 'easeOut'
                }
            }).then()
            rightAnimation.start({
                opacity: 1,
                y: 0,
                transition: {
                    duration: .5,
                    ease: 'easeOut'
                }
            }).then()
        } else {
            leftAnimation.start({
                opacity: 0,
                x: -100,
                transition: {
                    duration: .5,
                    ease: 'easeOut'
                }
            }).then()
            rightAnimation.start({
                opacity: 0,
                y: 100,
                transition: {
                    duration: .5,
                    ease: 'easeOut',
                    delay: .25
                }
            }).then()
        }
    },[inView,leftAnimation,rightAnimation])

    return <HeroContainer id={'levelup'} css={css`
        @media screen and (min-width: 1444px) {
            
            height:100vh;
        }
        `}>
        <HeroSection  revert ref={ref}>
                <div css={{ flex: 1, display:'flex', minHeight:350}} >
            <motion.img src={levelup} css={css`max-width:80%; margin:auto;`} animate={leftAnimation}/>
        </div>
        <motion.div css={css`
            flex:1;
            justify-content: center;
            align-items: center;
            display:flex;

          `}
          animate={rightAnimation}
          >

            <div css={css`
             justify-content: center;
            align-items: flex-start;
            margin: auto;
            display: flex;
            flex-direction: column;
            gap: 20px; 
            max-width: 70%;
            `}>


                <GradientHeading text="build your squad and" />
                <h1 css={css`
                word-break: break-word;

                margin:0 auto;
                @media screen and (min-width: 1444px) {
                    margin:0;
                }
                /* max-width: 500px; */
            `}>
                level them up
                </h1>
                <h3 css={css`
                margin-top:39px;
                margin-bottom:54px;
            `}>
                 Complete tasks and challenges to get XP NFTs. Use them to enhance your cards and take the advantage!
                </h3>
                <div css={css`
                display: flex;
                width: 100%;
                justify-content: center;
                @media (min-width: 1400px) {
                    justify-content:space-between;
                }
            `}>
{/*
                    <Button css={{ width: 'fit-content'}}>
                        play and earn
                    </Button> */}
                </div>
            </div>
        </motion.div>
        </HeroSection>
    </HeroContainer>
}
export default LevelUp;

