/** @jsxImportSource @emotion/react */
import { motion } from "framer-motion";
import {css} from '@emotion/react';
import { MouseEventHandler, ReactNode } from "react";
const Backdrop = ({ children, onClick }:{children:ReactNode, onClick: MouseEventHandler<HTMLDivElement>}) => {

  return (
    <motion.div
      css={css`
        position: fixed;        
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 50px;
        z-index: 1000;
        background-color: #0C092D99;
        display: flex;
        align-items: center;
        justify-content: center;
      `}
      onClick={onClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {children}
    </motion.div>
  );
};

export default Backdrop;
