/** @jsxImportSource @emotion/react */
import {useEffect} from "react";
import {ConfigService} from "../config.service";

const Redirect = () => {
    useEffect(()=>{
        if(ConfigService.isHost('zacatecas')){
            const {pathname} = window.location
            window.location.replace(`https://chainreaction.gg${pathname}`)
        }
        else window.location.replace(`/`)
    },[])
    return (<></>)
}
export default Redirect
