/** @jsxImportSource @emotion/react */

import Hero from 'components/Hero';
import LevelUp from 'components/LevelUp';
import Crafting from 'components/Crafting';
import Collections from 'components/Collections';
import GamePlay from 'components/GamePlay';
import Play from 'components/Play';
import Layout from 'components/Layout';

 const Home = () => {
    return (
        <Layout>
            <Hero />
            <LevelUp/>
            <Crafting/>
            <Collections/>
            <GamePlay/>
            {/* <News/> */}
            {/* <Partners/> */}
            <Play/>
        </Layout>
    )
}
export default Home
