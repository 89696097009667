/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import logo from '../assets/images/logo.png'
import polygon from '../assets/images/sponsors/polygon.png';
import { Link } from 'react-router-dom';
import getConfig from "config.service";

const Footer = () => {
    return <footer css={css`
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      flex-wrap: wrap;
      background: linear-gradient(0deg, #0088AD 0%, rgba(23, 200, 233, 0) 100%);
      padding: 87px;
      text-align: center;
      @media screen and (min-width: 1080px) {
        flex-direction: row;
        text-align: left;
        margin-top: 200px;
      }

      div {
        flex: 1;
      }

      h3 {
        font-family: Junegull, serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.05em;
        text-transform: uppercase;


      }

      ul {
        padding: 0;
        font-family: Varela Round, serif;
        display: flex;
        flex-direction: column;
        gap: 10px;

      }

      li {
        text-decoration: none;
        list-style: none none;

        & a {
          text-decoration: none;
          color: white;
        }
      }

    `}>
        <div css={css`
            display: flex;
            flex-direction: column;
            width: 100%;
        `}>
            <div css={css`text-align:center;margin-top:-20px`}>
                <img alt={'logo'} src={logo} css={{maxWidth:'100%'}}/>
            </div>
            <div css={css`text-align:center;margin-top:20px`}>
                <img alt={'polygon'} src={polygon} css={{maxWidth:'160px'}}/>
            </div>
        </div>
        <div>
            <h3>About</h3>
            <ul>
                {/* <li> Roadmap</li> */}
                <li><a rel="noreferrer" target={'_blank'} href={getConfig().litePaperURL}>Litepaper</a></li>
                <li><a rel="noreferrer" target={'_blank'} href={getConfig().ruleBookURL}>Rulebook</a></li>
                {/* <li>Factions info</li>
                <li>NFTs info</li> */}
            </ul>
        </div>
        <div>
            <h3>customer service</h3>
            <ul>
                {/* <li>FAQ</li>
                <li>Contact Us</li> */}

                <li><a rel="noreferrer" target={'_blank'} href={"https://discord.gg/52Mm2wASSu"}>Discord</a></li>
            </ul>
        </div>
        <div>
            <h3>Polices</h3>
            <ul>
                <li><Link to={"/privacy"}>Privacy</Link></li>
                <li><Link to={"/terms-and-conditions"}>Terms and Conditions</Link></li>
            </ul>
        </div>
    </footer>
}
export default Footer;

