/** @jsxImportSource @emotion/react */
import { motion } from "framer-motion";
import { css, useTheme } from '@emotion/react';
import { MouseEventHandler, ReactNode, useState } from "react";
import styled from '@emotion/styled'
import { Button } from "components/Button";
import { currentDeckAtom, currentDeckSelector, decksFetchAtom } from "store";
import { useRecoilCallback, useRecoilValueLoadable } from "recoil";
import { DeckRepository } from "repositories/deck.repository";





export const containerStyles = css`
    background: #070830;
    border: 2px solid #FFFFFF;
    border-radius: 20px;
    margin : auto;
    padding:20px;
    color: #fff;
    font-size: 14px;
    min-height:50vh;
     @media screen and (min-width: 900px) {
         max-width: 700px;
     }
`
const centered = css`
    display: flex;
    justify-content: center;
    align-items: center;
    `
const P = styled.p`
  width: 100%;
  text-align: center;
  font-family: 'Junegull', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  /* or 117% */
  letter-spacing: 0.03em;
  text-transform: uppercase;

  span {
    color: ${props => props.theme.color.primary};
  }

  @media screen and (min-width: 900px) {
    font-size: 24px;
  }
`

const DeleteDeck = ({ onClick, onClose }: { children: ReactNode, onClick: MouseEventHandler<HTMLDivElement>, onClose: Function }) => {
    const deck = useRecoilValueLoadable(currentDeckSelector);
    const theme = useTheme()
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const deleteDeck = useRecoilCallback(({ snapshot, set }) => async () => {
        setLoading(true);
        try{
            await DeckRepository.deleteDeck(deck.contents.id);
            setSuccess(true);
            await set(currentDeckAtom, null)
            const fa = await snapshot.getPromise(decksFetchAtom);
            set(decksFetchAtom,() => fa+1);
            setTimeout(() =>
            {
                setSuccess(false)
                onClose()
            }, 1000);
        } catch (error ){
            const e: any = error;
            setError(e.message);
        }
        setLoading(false);
    })

    if(loading) return <div css={[containerStyles, centered]}>Deleting...</div>
    if(error) return <div css={[containerStyles, centered]}>Error: {error} </div>
    if(success) return <div css={[containerStyles, centered]}>Success!</div>


    if (deck.state === 'loading') return <motion.div
        css={containerStyles}
        onClick={onClick}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
    >
        Loading...
    </motion.div>


    return (
        <motion.div
            css={[containerStyles, css`
                justify-content: space-around;
                display: flex;
                flex-direction: column;
            `]}
            onClick={onClick}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <h2 css={css`width: 100%; text-align:center;`}>
                Delete deck
            </h2>

            <P>
                you are about to delete your deck  <span
                css={{color: theme.color.primary }}
                >{deck.contents.name}</span >, this action cannot be reversed
            </P>
            <P>
                are you sure you want to proceed?
            </P>
            <div css={css`
            display: flex;
            flex-direction: column;
            gap: 40px;
            div{
                margin: auto;
                max-width: 250px;
                width: 100%;

            }
            @media screen and (min-width: 600px) {
                flex-direction: row;
                justify-content: center;
                div {
                    flex: 1;
                    padding:20px;
                }
            }
        `}>
                <Button color={'secondary'} onClick={() => onClose()}>
                    Cancel
                </Button>
                <Button onClick={deleteDeck}>
                    Delete
                </Button>
            </div>
        </motion.div>
    );
};

export default DeleteDeck;

