import Layout from "components/Layout";
import TermsWindows from "components/TermsWindow";

import { StyledTerms } from "./StyledTerms";

const LayoutTerms = ({ terms, title = ""}: any) => {
  return (
    <Layout>
      <StyledTerms>
        <div className="terms-and-condition-header">
          <h1 className="terms-and-condition-title">{title}</h1>
        </div>
        {terms.map((term: any) => {
          return (
            <TermsWindows
              title={term.title as string}
              paragraphs={term.paragraphs as string[]}
            />
          );
        })}
      </StyledTerms>
    </Layout>
  );
};

export default LayoutTerms;
