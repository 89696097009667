/** @jsxImportSource @emotion/react */
import {useEffect} from "react";
import {ConfigService} from "../config.service";

const Blank = () => {
    useEffect(()=>{
        ConfigService.walletListener()
    },[])
    return (<></>)
}
export default Blank
