import styled from "@emotion/styled";

export const StyledTermWindow = styled.div`
  width: 100%;
  margin: 0 auto 4em;
  .header {
    display: flex;
    width: 100%;
  }
  .title {
    font-size: 1.8em;
    text-align: left;
    margin: 0;
    text-shadow: 1px 0 0 hsl(301deg 100% 25%), -1px 0 0 hsl(301deg 100% 25%), 0 1px 0 hsl(301deg 100% 25%), 0 -1px 0 hsl(301deg 100% 25%), 1px 1px hsl(301deg 100% 25%), -1px -1px 0 hsl(301deg 100% 25%), 1px -1px 0 hsl(301deg 100% 25%), -1px 1px 0 rgb(56 113 133);
  }
  .body {
    margin: auto;
    padding: 0 .5em;
  }
  .paragraph,
  .terms-list {
    color: white;
    font-size: 1.2em;
    font-weight: 300;
    a {
      text-decoration: underline;
      color: white;
      font-weight: 400;
    }
    strong {
      font-weight: 400;
    }
  }
  .terms-list {
    li {
      margin-bottom: .5em;
    }
  }
`;