/** @jsxImportSource @emotion/react */
import Layout from "components/Layout"
import { css } from '@emotion/react';
import { Button } from "components/Button";
import { useRecoilValueLoadable } from "recoil";
import { decksSelector } from "store";
import Modal from "components/Modal";
import { useState } from "react";
import NewDeck from "components/DeckBuilder/NewDeck";
import DecksComponent from "components/DeckBuilder/Decks";
import CollectorComponent from "components/DeckBuilder/collector";
import CardList from "components/DeckBuilder/CardList";
import FilterAndSort from "components/DeckBuilder/FilterAndSort";
import { useWindowDimensions } from "utils";

const DeckBuilder = () => {
    const decks = useRecoilValueLoadable(decksSelector);
    const windowDimentions = useWindowDimensions()
    const renderNodata = () => {
        if (decks.state === "loading") {
            return <div css={css`display:flex; justify-content:center; align-items:center; height:100vh;`}><h2>Loading decks...</h2></div>
        }
        if (decks.state === "hasError") {
            return <div>Error</div>
        }
        if (decks.state === "hasValue" && decks.contents.length === 0) {
            return <NoDecks />
        }
        return null
    }

    return (

        <Layout>
            {renderNodata() || (
            <div css={css`display:flex;flex:1;width:100%;color: white;flex-direction:column;`}>

             <div
                css={css`
                
                display:flex;
                border-top: 1px solid rgba(255,255,255,0.1);
                border-bottom: 1px solid rgba(255,255,255,0.1);
                justify-content: space-between;
                width: 100%;
                >div{
                    height: 70px;
                    display: flex;
                }
                `}>

                    <DecksComponent />
                    <div css={css`overflow:visible;justify-content: flex-end; flex:1;`}>
                        {windowDimentions.width > 1000 && <FilterAndSort />}
                        <div css={css`overflow:hidden;`}>

                        <Button css={css`max-height:43px; font-size:12px; margin: auto 0; `} color="secondary" onClick={() => {}}>
                            Play
                        </Button>
                        </div>
                    </div>
                </div>
                <div css={css`
                    @media screen and (max-width: 1444px) {
                        margin-bottom: 20px;
                        
                    }
                `}>
                {windowDimentions.width < 1000 && <FilterAndSort />}
                </div>
                <div css={css`display:flex; flex:1; flex-direction:row; min-height:100vh; padding-top: 30px; position:relative;`}>
                    <CollectorComponent/>
                    <CardList />
                    </div>
            </div>)}

        </Layout>

    )

}

//no decks component
const NoDecks = () => {
    const [open, setOpen] = useState(false);

    const closeModal = () => {
        setOpen(false);
    }
    /*
    const openNewDeck = () => {
        setOpen(true);
    }
    */

    return (

            <div css={css`
            width: 100%;
            height: calc(100vh - 94px);
            background: rgb(255,255,255);
            background: linear-gradient(0deg, rgba(255,255,255,0.05) 30%, rgba(255,255,255,0.3) 100%);
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            `}>
                <Modal opened={open} handleClose={() => closeModal()}>
                     <NewDeck onClose={() => closeModal()} children={undefined} onClick={() => { }} />
                </Modal>
                <h2 css={css`color: white; margin-right: auto; margin-left:auto;`}> You don’t have decks yet </h2>
                <Button css={css`max-width:321px; margin:0 auto; width:321px;`} onClick={() => setOpen(true)}>
                    Create a deck
                </Button>
                <div css={css`
                padding: 20px;
                display: flex;
                flex-direction: column;
                gap: 50px;
                max-width: 321px;
                margin: 0 auto;
                @media screen and (min-width: 600px) {
                    max-width: 100%;
                    justify-content: center;
                    
                }
            `}>
                <div css={css`                
                    display: flex;
                    flex-direction: column;
                    gap: 50px;
                    /* justify-content: center;
                    align-items: center; */
                    @media screen and (min-width:800px) {
                        flex-direction: row;
                        justify-content: center;
                
                        align-items: center;
                    }
                `}>

                        <Button color="secondary" css={css`min-width:321px;`}>
                            open booster pack
                        </Button>
                        <Button color="secondary" css={css`min-width:321px;`}>
                            get cards
                        </Button>
                    </div>
                </div>
            </div>

    )
}



export default DeckBuilder
