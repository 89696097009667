/* eslint-disable */
import getConfig from 'config.service'

export function getHost(type?: 'deck'|'tournaments') {
  switch(type){
    case 'deck':
      return process.env.REACT_APP_DECK_HOST || getConfig().hostURL
    case 'tournaments':
      return process.env.REACT_APP_TOURNAMENTS_HOST || getConfig().hostURL
    default:
      return type ? 'http://localhost:8089' : getConfig().hostURL
  }
}
