/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Button } from './Button';
import Modal from './Modal';


const Alert = ({onClose, opened, text}:any) => {
    return <Modal handleClose={onClose} opened={opened} >
        <div css={css`border: 2px solid white; border-radius:30px; padding:20px; color:white; p {margin: 50px 0; }`}>
            <h3>Alert</h3>
            <p>{text}</p>
            <Button onClick={() => onClose()}>close</Button>
        </div>
    </Modal>
}

export default Alert