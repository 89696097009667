/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";

import CardBoxPNG from '../../assets/cardparts/card-box.png'
import RarityUncommonPNG from '../../assets/cardparts/rarity-uncommon.png'

const CardContainer = styled.div`
        aspect-ratio: 13/20;
        display: flex;
        flex: 1;
        width: 220px;
        min-width: 220px;
        position: relative;
        z-index: 0;

        img {
                position: absolute;
                max-width: 100%;
                max-height: 100%;
                width: 100%;
                height: 100%;
        }

        .supportHp {
                z-index: 5;
                position: absolute;
                font-size: 16px;
                font-weight: bold;
                color: mediumseagreen;
                top: 35px;
                left: -4px;
                width: 90px;
                height: 55px;
                transform: rotate(-45deg);
                display: flex;
                justify-content: space-around;
                flex-direction: column;
                text-align: center;
                text-shadow: 0 0 10px black;
        }

        .supportAtk {
                z-index: 5;
                position: absolute;
                font-size: 16px;
                font-weight: bold;
                color: rgb(193, 56, 131);
                top: 34px;
                right: -1px;
                width: 90px;
                height: 55px;
                transform: rotate(45deg);
                display: flex;
                justify-content: space-around;
                flex-direction: column;
                text-align: center;
                text-shadow: 0 0 10px black;
        }

        .linkPoints {
                color: RGB(245, 245, 118);
                font-size: 12px;
                height: 16px;
                width: 26px;
                z-index: 4;
                top: 204px;
                left: 10px;
                position: absolute;
                justify-content: space-around;
                flex-direction: column;
                text-align: center;
        }

        .name {
                color: white;
                z-index: 4;
                position: absolute;

                font-size: 12px;
                left: 40px;
                right: 40px;
                bottom: 119px;
                display: flex;
                justify-content: space-around;
                flex-direction: column;
                text-align: center;
        }

        .level {
                color: RGB(109, 69, 24);
                font-size: 10px;
                font-weight: bold;
                height: 16px;
                width: 18px;
                z-index: 4;
                position: absolute;
                top: 205px;
                right: 15px;
                display: flex;
                justify-content: space-around;
                flex-direction: column;
                text-align: center;
        }

        .description {
                color: white;
                z-index: 4;
                position: absolute;
                font-size: 10px;
                left: 50%;
                min-height: 50px;
                min-width: 180px;
                transform: translateX(-50%);
                bottom: 20%;
                display: flex;
                justify-content: space-around;
                flex-direction: column;
                text-align: center;
                line-height: 1.3;
        }

        .collectionName {
                color: white;
                z-index: 4;
                position: absolute;
                font-size: 8px;
                font-weight: lighter;
                left: 25%;
                bottom: 16%;
        }

        .setNumber {
                color: white;
                z-index: 4;
                position: absolute;
                font-size: 8px;
                font-weight: lighter;
                right: 25%;
                bottom: 16%;
        }

        .evaporatingPoints {
                color: RGB(207, 123, 233);
                font-size: 20px;
                z-index: 4;
                position: absolute;
                left: 30px;
                right: 35px;
                bottom: 7%;
                display: flex;
                justify-content: space-around;
                flex-direction: column;
                text-align: center;
        }

        .atk {
                z-index: 5;
                position: absolute;
                font-size: 20px;
                font-weight: bold;
                color: rgb(193, 56, 131);
                bottom: 21px;
                left: -6px;
                width: 90px;
                height: 55px;
                transform: rotate(45deg);
                display: flex;
                justify-content: space-around;
                flex-direction: column;
                text-align: center;
        }

        .hp {
                z-index: 5;
                position: absolute;
                font-size: 20px;
                font-weight: bold;
                color: mediumseagreen;
                bottom: 21px;
                right: -7px;
                width: 90px;
                height: 55px;
                transform: rotate(-45deg);
                display: flex;
                justify-content: space-around;
                flex-direction: column;
                text-align: center;
        }

        .shufflePoints {
                position: absolute;
                background: rgb(193, 56, 131);
                top: 1px;
                left: 0;
                height: 43px;
                width: 53px;
                text-align: center;
                clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
                display: flex;
                justify-content: space-around;
                flex-direction: column;
                font-size: 24px;
                font-weight: bold;
                color: lightpink;
        }

        .shufflePoints-wrapper {
                content: "";
                z-index: 6;
                position: absolute;
                background: lightpink;
                top: 48%;
                left: 3%;
                height: 45px;
                width: 50px;
                text-align: center;
                box-sizing: border-box;
                clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
                transform: scale(0.6);
        }
        .background {
                width: 90%;
                height: 50%;
                position: absolute;
                top: 12%;
                left: 5%;
                background: none black no-repeat scroll 90% 98%;
                background-size: 200% 280%;
                clip-path: polygon(16% 0, 84% 0, 100% 16%, 100% 100%, 0 100%, 0 16%);
                overflow: hidden;
        }
        .picture {
                width: 80%;
                height: 52%;
                position: absolute;
                top: 8%;
                left: 12%;
                background: none transparent no-repeat scroll 105% 0;
                background-size: 180% 180%;
                overflow: hidden;
        }
`

const Card = ({card, className}:any) => {
        let bg = card.art
        if(!bg || bg === ''){
                bg = `/data/cards/${card.number}.png`
        }

    return <CardContainer className={className} title={card.number}>
            <div className="background" style={{backgroundImage:`url(${bg})`}} />
            <img alt="rarity uncommon" src={RarityUncommonPNG} />
            <div className="picture" style={{backgroundImage:`url(${bg})`}} />
            <img alt="card box" src={CardBoxPNG} />
            <div className="supportHp">+{card.hpS}</div>
            <div className="supportAtk">+{card.atkS}</div>
            <div className="linkPoints">{card.lp}</div>
            <div className="name">{card.name}</div>
            <div className="level">{card.lvl}</div>
            <div className="description">{card.description}</div>
            <div className="collectionName">{card.collection}</div>
            <div className="setNumber">#{card.number}</div>
            <div className="atk">{card.atk}</div>

            <div className="hp">{card.hp}</div>
            <div className="evaporatingPoints">{card.ep}</div>
            <div className="shufflePoints-wrapper">
                <div className="shufflePoints">{card.shufflePoints}</div>
            </div>
     </CardContainer>
}
export default  Card;
