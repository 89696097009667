/** @jsxImportSource @emotion/react */
import { ThemeProvider } from '@emotion/react'
import {RecoilRoot} from 'recoil';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "swiper/css/bundle";
export const theme = {
  color: {
    secondary: '#4D4883',
    primary: 'rgba(255, 0, 249, 1)',
    success: '#00C851',
    danger: '#F00',

  }
} 


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <ThemeProvider theme={theme}>
        <RecoilRoot>
          <App />
        </RecoilRoot>
  </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
