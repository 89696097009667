/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useState } from 'react';
import logo from '../assets/images/logo.png';
import { Button } from './Button';
import { BsDiscord } from 'react-icons/bs';
import { IconType } from 'react-icons';
import {HiMenuAlt3} from 'react-icons/hi'
import {TiTimes} from 'react-icons/ti'
import getConfig, {ConfigService} from "config.service";

function Header() {
    const [menuOpen, setMenuOpen] = useState(false);

    function clickOnMenu(url:string,target?:string){
        if(!target) window.location.href = url
        else window.open(url,target)
        setMenuOpen(false)
    }

    return (
        <header css={css`
        font-family: Junegull,serif;
        height: 94px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: sticky;
        top:0;
        width: 100%;
        z-index: 10;
        background-color: #0C092D;;
        button {
            font-family: Junegull,serif;
        }
        `} >


            <div css={css`
            display:${!menuOpen ? 'flex' : 'none'};
            align-items: center;
            justify-content: center;
            width: 177px;
            height:57px;
            padding-left: 20px;
            transition:all 0.3s ease-in-out;
            @media (min-width: 1440px) {
                display: flex;
            }
            `}>
                <img onClick={()=> {
                    window.location.href = "/#";
                }} alt={'logo'} src={logo} css={{ cursor: 'pointer',width: "100%" }} /></div>
            <NavContainer open={menuOpen}>

                <Burguer onClick={() => setMenuOpen(!menuOpen)} open={menuOpen} />
                <div css={css`
                display:flex;
                flex:1;
                flex-direction: column;
                @media (min-width: 1440px) {
                    flex-direction: row-reverse;
                    
                }
            `}>
                    <div
                        css={css`
                    height:231px;
                    display:${!menuOpen ? 'none' : 'flex'};
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                    @media (min-width: 1440px) {
                     display:flex;
                        flex-direction: row;
                        height:100%;
                        gap:18px;
                        padding:0 18px;
                        }
                    `}
                    >
                        <Button
                            href={'https://discord.gg/52Mm2wASSu'}
                            target={'_blank'}
                            color='secondary'
                            variant={'icon'}
                            icon={<BsDiscord /> as unknown as IconType}
                        // css={css`
                        //     width:222px;
                        //     height:58px;
                        //     @media (min-width: 1440px) {
                        //      width: 66px;
                        //     }
                        // `}
                        >
                        </Button>
                        <Button
                            onClick={()=>ConfigService.goToPlay()}
                            css={css`
                        max-width:222px;
                        min-width: 222px;
                    `}
                        // css={css`
                        //     width:222px;
                        //     height:58px;
                        // `}


                        >PLAY</Button>
                    </div>

                    <NavLinks open={menuOpen}>
                        <Link onClick={()=>clickOnMenu('/#')}>Home</Link>
                        <Link onClick={()=>clickOnMenu('/#levelup')}>Level Up</Link>
                        <Link onClick={()=>clickOnMenu('/#crafting')}>Crafting</Link>
                        <Link onClick={()=>clickOnMenu('/#collections')}>Our Collection</Link>
                        <Link onClick={()=>clickOnMenu('/#gameplay')}>Gameplay</Link>
                        <Link onClick={()=>clickOnMenu(getConfig().litePaperURL,'_blank')}>Litepaper</Link>
                        <Link onClick={()=>clickOnMenu(getConfig().ruleBookURL,'_blank')}>Rulebook</Link>
                    </NavLinks>
                </div>
            </NavContainer>
        </header >
    );
}

const BurguerContainer = styled.div<{ open: boolean }>`
    height:100px;
    width: 100px;
    color: white;
    font-size: 40px;
    align-self:flex-end;
    justify-content: center;
    align-items: center;
    display:flex;
    flex-direction: column;
    cursor: pointer;

    @media (min-width: 1440px) {
      display:none
    }
    
    ${props => props.open && `
        position: absolute;
        top: 0;
        right: 0;
   
    `}
`
const Burguer = ({ onClick, open }: { onClick: Function, open: boolean }) => {

    return (<BurguerContainer onClick={(e) => onClick(e)} open={open}>
        {open ?  <TiTimes /> :<HiMenuAlt3 />}
    </BurguerContainer >)

}
const NavContainer = styled.nav<{ open: boolean }>`
    display: flex;
    justify-self: flex-end;
    transition: all 0.1s ease-in-out;
    @media (max-width: 1440px) {
        height: ${props => props.open ? '100vh' : '100%'};
        ${props => props.open && css`
        position: fixed;
        top:0;
        width: 100vw;
        height: calc(100vh - 100px);
        z-index:10;
        background: #11123E;
        flex-direction: column;
        flex:1;
        padding-top: 100px
        `}
        
    }
    @media (min-width: 1440px) {
        position: relative;
        height: 100%;
        width: 100%;
        flex-direction: row;
      }

`

const NavLinks = styled.ul<{ open?: boolean }>`
    display: ${props => props.open ? 'flex' : 'none'};
    flex-direction: column;
    width:100vw;
    justify-content: space-around;
    height:100%;
    margin:0;
    list-style-type: none;
    padding:0;
    align-items: center;
    background: #1c1d5a ;
 
    @media (min-width: 1440px) {
        width: 100%;
        max-width:80%;
        display: flex;
        flex-direction: row;
        align-items: center;
        background: transparent;
        justify-content:center;
    
      }

`
const Link = styled.li`
  list-style: none;
  text-decoration: none;
  min-width: 100px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  height:fit-content;
  padding:10px;
  color: white;
  &:hover {
    text-shadow: 0 3px 10px #F5F5F5;
  }
  :active{
      color:#16CCEC;
      text-shadow: 0 3px 10px #16CCEC;
  }
  @media (max-width: 1440px) {
      height:100%;
      max-height: 53px;
      width: 100%;
      &:active{
        background: rgba(255, 255, 255, 0.25);
        color: #FF6CFF;
        text-shadow: 0 3px 10px #FF6CFF;
      }
  }
`
export default Header;
