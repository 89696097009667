/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Footer from "./Footer";
import Header from "./Header";


const Layout = ({ children }:any) => {
    return <div css={css`min-height: 100vh; display:flex;flex-direction:column; width:100%;`}>
    <Header />
        <div css={css`padding-top:94px; width:100%;`}>
            {children}
        </div>
    <Footer/>    
    </div>
}

export default Layout;