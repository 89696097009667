import {atom, selector} from "recoil";
import {CardRepository} from "repositories/card.repository";
import {DeckRepository} from "repositories/deck.repository";

export const decksFetchAtom = atom({
    key: 'decksFetchAtom', default: 0, effects_UNSTABLE: undefined
})
// decks selector
export const decksSelector = selector({
    key: "decksSelector",
    get: async ({ get }) => {
        get(decksFetchAtom);
        return await DeckRepository.getDecks();
    }
})


export const currentDeckAtom = atom<any | undefined>({
    key: 'currentDeckAtom', default: undefined, effects_UNSTABLE: undefined
})


export const currentDeckSelector = selector<any>({
    key: 'currentDeckSelector',
    get: async ({ get }) => {
        const deckList = await get(decksSelector)
        const currentDeck = await get(currentDeckAtom)

        if (!currentDeck) {
            return deckList.length > 0 && deckList[0]
        } else {
            return currentDeck
        }
    }
})

export const cardsSelector = selector({
    key: "cardsSelector",
    get: async ({ get }) => {
        const filters = get(filtersAtom)
        return await CardRepository.getCards(filters);
    }
})


// collections selector
export const collectionsSelector = selector({
    key: "collectionsSelector",
    get: async () => {
        // const cards = await get(cardsSelector)
        // const collections = uniq(cards.map((card: any) => card.collection))
        // console.log({collections}
        return ['Chain Reaction', 'Deviants Factions'];
    }
})

// filters atom
export const filtersAtom = atom<any>({
    key: 'filtersAtom', default: {
        filters: [],
        sortBy:{
            attribute: '',
            type: ''
        }

    }, effects_UNSTABLE: undefined
})
