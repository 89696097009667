import getConfig from "config.service";

export const ValidateIfTokenIsPresent = (): string => {
    const token = getConfig().token
    try {
        const sessionStr = localStorage.getItem('session')
        if (!sessionStr) return token
        const session = JSON.parse(sessionStr ?? '')
        if (!session.token) return token
        return session.token
    } catch (e) {
        return token
    }
}

export const ValidateIfUserIsPresent = (): string => {
    return getConfig().user
}

type sessionData = {
    wallet: string,
    token: string,
}

export const getSessionData = (): sessionData|undefined => {
    const sessionString = localStorage.getItem('session')
    if (!sessionString) return undefined
    return JSON.parse(sessionString)
}

export const sessionExpired = (): void => {
    localStorage.removeItem('session')
    window.location.reload()
}
