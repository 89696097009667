

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';



const MarqueeContainer = styled.div<{text:string}>`
    position: relative;
    display: block;
    width: 100%;
    height: 280px;
    top: 0;
    left: 0;
    overflow: hidden;

.marquee {
    position: absolute;
    display: block;
    margin: auto auto;
    white-space: nowrap;
    overflow: hidden;
    min-width: 100%;
    height: 100%;
}
.marquee div {
    display: inline-block;
    height: 100%;
    animation: marquee 10s linear infinite;
    mix-blend-mode: hard-light;
}

@keyframes marquee {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-100%, 0);
    }
}
`

const mainSpancss = css`
    font-family: 'Junegull';
    font-style: normal;
    font-weight: 400;
    font-size: 192.426px;
    line-height: 181px;
    text-transform: uppercase;
    background: linear-gradient(116.1deg, rgba(255, 255, 255, 0.3) 17.34%, rgba(255, 255, 255, 0) 31.77%), linear-gradient(90.98deg, #16CCEC 1.54%, rgba(0, 163, 255, 0.55) 47.37%, rgba(212, 0, 207, 0) 96.48%), #FF6CFF;
    /* -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    background-clip: text;
    text-fill-color: transparent;
    text-shadow: 0px 16.1127px 16.1127px rgba(0, 0, 0, 0.25);
    text-align: center;
    white-space: nowrap;
    width: fit-content;
    margin: 0 auto;
    z-index: 1;
`
const styleSpancss = css`
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,.6) 10%, rgba(255,255,255,0) 80%);
    font-family: 'Junegull';
     /* mix-blend-mode: hard-light; */
    font-style: normal;
    font-weight: 400;
    font-size: 192.426px;
    line-height: 181px;
    position: absolute;
    text-align: center;
    width:100%;
    top: 0;
    left: 0;
    z-index: -1;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 5px transparent;

`

const Marquee = ({ text, className }: { text: string, className?: string }) => {
    return <MarqueeContainer text={text} className={className} >
        <div className="marquee">
            <div css={css`
            padding-left: 100%;
            `}>
                <div css={css`position:relative;`}>
                    <span css={mainSpancss}>
                        {text}
                    </span>
                    <span css={styleSpancss}>
                        {text}
                    </span>
                </div>
                
                </div>
        </div>
    </MarqueeContainer>
}
export default Marquee;

