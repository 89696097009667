/** @jsxImportSource @emotion/react */
import { motion } from "framer-motion";
import {css } from '@emotion/react';
import { MouseEventHandler, ReactNode, useState } from "react";
import check from 'assets/svg/check.svg';
import errorSVG from 'assets/svg/error.svg';
import styled from '@emotion/styled'
import { Button } from "components/Button";
import { useRecoilCallback } from "recoil";
import { DeckRepository } from "repositories/deck.repository";
import { currentDeckAtom, decksFetchAtom } from "store";

const List = styled.ul`
  color: #fff;
  font-size: 14px;
  list-style-position: inside;
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-family: Varela Round,serif;
  margin: 20px 0 30px 0;

  li {
    line-height: 20px;
    display: flex;

    margin: 0;
    padding: 0 0 0 30px;

    :before {
      content: '';
      background-size: contain;
      width: 20px;
      height: 20px;
      position: absolute;
      left: 0;

    }
  }
`

const Text = styled.input`
    color:white;
    height: 43px;
    border: none;
    outline: none;
    background-color: black;
    text-indent: 10px;
    width: 100%;
    font-family: junegull,serif;
    font-size: 16px;
    margin-bottom: 10px;
    ::placeholder{
        color: rgba(92, 92, 92, 1);
    }
    :focus{
        border: 1px solid #3D3A57;
    }

`
export const containerStyles = css`
    background: #070830;
    border: 2px solid #FFFFFF;
    border-radius: 20px;
    margin : auto;
    padding:20px;
    color: #fff;
    font-size: 14px;
     @media screen and (min-width: 900px) {
         max-width: 700px;
     }
`
const centered = css`
    display: flex;
    justify-content: center;
    align-items: center;
    `
const NewDeck = ({ onClick, onClose }:{children:ReactNode, onClick: MouseEventHandler<HTMLDivElement>, onClose:Function}) => {
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const validations = {
        legth: name.length>3,
        noEmpty: name.length>0,
        hasNoSpecialChars: /^[a-zA-Z0-9 ]*$/g.test(name),
        validate: () => {
            return validations.legth && validations.noEmpty && validations.hasNoSpecialChars
        }
    }


    const saveDeck = useRecoilCallback(({ snapshot, set }) => async () => {
        setLoading(true);

        try{
            const result = await DeckRepository.createDeck(name.trimEnd());

            setSuccess(true);
            await set(currentDeckAtom, result.deck)
            const fa = await snapshot.getPromise(decksFetchAtom);
            await set(decksFetchAtom,() => fa+1);
            setTimeout(() =>
            {
                setSuccess(false)
                onClose()
            }, 1000);
        } catch (error ){
            const e: any = error;
            setError(e.message);
        }
        setLoading(false);
    })


 const specialChars = "( # $ % & / ( ) = ’ ¿ ? | ° } { [ ] * + < > )<"

 if(loading) return <div css={[containerStyles, centered]}>Saving...</div>
 if(error) return <div css={[containerStyles, centered]}>Error: {error} </div>
 if(success) return <div css={[containerStyles, centered]}>Success!</div>

  return (
    <motion.div
      css={containerStyles}
      onClick={onClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
        <h2 css={css`width: 100%; text-align:center;`}>
            create new deck
        </h2>
        <h3 css={css`margin-bottom:5px; padding:0;`}>Enter the name</h3>
        <Text type="text" placeholder="name" css={css`width: 100%;`} maxLength={22} onChange={(ev) => setName(ev.currentTarget.value.trimStart())} value={name}/>
        {/* <span css={css`font-family: Varela Round;`}>Your Deck Name must have the following: </span> */}
        <List>
            <li css={css`:before{background-image: url(${validations.legth ? check : errorSVG})}`}>Minimum 4 characters</li>
            <li css={css`:before{background-image: url(${validations.hasNoSpecialChars ? check : errorSVG})}`}>No special characters like {specialChars} </li>
            <li css={css`:before{background-image: url(${validations.noEmpty ? check : errorSVG})}`}>No empty name</li>
        </List>
        <div css={css`
            display: flex;
            flex-direction: column;
            gap: 40px;
            div{
                margin: auto;
                max-width: 250px;
                width: 100%;

            }
            @media screen and (min-width: 600px) {
                flex-direction: row;
                justify-content: center;
                div {
                    flex: 1;
                    padding:20px;
                }
            }
        `}>
            <Button color={'secondary'} onClick={()=> onClose()}>
                Cancel
            </Button>
            <Button  onClick={saveDeck} disabled={!validations.validate()}>
                Create
            </Button>
        </div>
    </motion.div>
  );
};

export default NewDeck;

