import styled from "@emotion/styled";

export const StyledTerms = styled.div`
  width: 100%;
  max-width: 320px;
  margin: 1em auto;
  @media screen  and (min-width: 580px) {
    max-width: 560px;
  }
  @media screen  and (min-width: 1080px) {
    max-width: 800px;
  }
  .terms-and-condition-header {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 3em;
    .terms-and-condition-title { 
      font-size: 2.5em;
      text-align: center;
      margin: 0;
    }
  }
`;