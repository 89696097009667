
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Button } from "components/Button";
import React, { useEffect, useRef, useState } from "react";
import { useRecoilCallback, useRecoilValueLoadable } from "recoil";
import { DeckRepository } from "repositories/deck.repository";
import { TiTimes } from 'react-icons/ti'
import { currentDeckAtom, currentDeckSelector } from "store";
import { IconType } from "react-icons";
import { motion } from "framer-motion";
// import _ from 'lodash'
import { FaAngleDoubleDown, FaAngleDoubleUp, } from "react-icons/fa";
import titleImage from 'assets/images/deck-title.png'
import useScrollBlock from 'utils/scrollBlock'
import { useOnClickOutside } from "usehooks-ts";

const ControlsContainer = css`
    width: 100%;
    display: flex;
    background-color: rgba(17, 18, 62, 1);
    /* background: linear-gradient(180deg, rgba(225, 132, 223, 0.5) 0%, rgba(246, 15, 242, 0) 16.93%); */
    flex-direction: column;
    clip-path: polygon(15px 0, calc(100% - 15px) 0, 100% 15px, 100% 100%, 0 100%, 0 15px);
    /* clip-path: polygon(15px 0, calc(100% - 15px) 0, 100% 15px, 100% 100%, 0 100%, 0 15px); */
`

const CollectorComponent = () => {
    const [open, setOpen] = useState(false);
    const currentDeck = useRecoilValueLoadable(currentDeckSelector)
    const [blockScroll,allowScroll] = useScrollBlock()

    const  ref = useRef<HTMLDivElement>(null)

    const handleClickOutside = () => {
        setOpen(false)
      }

      useOnClickOutside(ref, ev => {
        const target = (ev.target as HTMLDivElement )

        if (![target.id,(target.parentNode as HTMLDivElement).id].includes("deck-actions")) {
            handleClickOutside()
        }
    })


    useEffect(() => {
        if(open) return blockScroll()
        allowScroll()

        return () => {
            allowScroll()
        }
    }, [open,allowScroll,blockScroll])
    // const scrollInfo = useScroll()
    // console.log(scrollInfo.top)
    // const height = scrollInfo.top < 150 ? 215 - scrollInfo.top : 70

    if ('loading' === currentDeck.state) {
        return <div>Loading</div>
    }

    if ('hasError' === currentDeck.state) {
        return <div>Error</div>
    }
    const renderCards = (deck: any) => {
        return deck.cards.map((card: any, i: number) => {
            if(i===12) return <div key={card.id}>
                <hr css={{opacity:.1}}/>
                <DeckCard key={card.id} card={card} />
            </div>
            return <DeckCard key={card.id} card={card} />
        }
        )
    }
    const renderPlaceHolders = ({cards}:any) => {
        if(cards.length >= 12 ) return null
        return [...Array(12 - cards.length ).keys()].map(i => (<CardPlaceholder key={12 - i}/>))

    }

    return (
        <div css={css`
            display: flex;
            flex: 1;
            /* padding:20px; */
            padding-bottom: 0;
            /* min-width: 321px; */
            width: 90%;
            max-width: 400px;
            padding-top: 0;
            height: 100%;
            position: fixed;
            z-index: 5;
            bottom: ${open ? '0' : '-71vh'};
            left:50%;
            transform: translateX(-50%);
            transition: all 0.2s ease-in-out;
            max-height: 80vh;
            filter: drop-shadow(0px -3px 3px #F651DC) drop-shadow(0px -4px 15px rgba(246, 19, 182, 0.8));
            /* box-shadow: 0px -3px 7px #F651DC, 0px -16px 44px rgba(246, 19, 182, 0.8); */
            /* overflow-y: scroll; */
            @media screen and (min-width: 1080px) {
                transform: translateX(0%);
                max-height: calc(100vh - 94px - 70px - 30px - 10px); // header controls + padding
                margin: 0 10px;
                display: flex;
                flex: 1;
                position: sticky;
                top:50px;
                /* max-width: 400px; */
                min-width: 400px;
                width: 100%;
                left:0;

                z-index: 0;
               /* box-shadow: 0px -3px 7px #F651DC, 0px -16px 44px rgba(246, 19, 182, 0.8);  */
            }
        `}
        ref={ref}
        >

            <div css={css`
                position: absolute; 
                left: 50%; 
                top: -40px;
                transform: translateX(-50%);
                width: fit-content;
                z-index: 2;
                @media screen and (min-width: 1080px) {
                    display: none;
                }`}>
                <Button onClick={() => { setOpen(!open) }} variant="icon" icon={(!open ? <FaAngleDoubleUp/> : <FaAngleDoubleDown/>) as unknown as IconType} />
            </div>
            <div css={css`
                position: absolute; 
                left: 50%; 
                top: -20px;
                transform: translateX(-50%);
                width: fit-content;
                z-index: 2;
                display: flex;
                justify-content: center;
                font-family: North Zone,serif;
                filter: drop-shadow(0px 2px 2px #000);
                @media screen and (max-width: 1080px) {
                    display: none;
                }`}>
                <img alt="title" src={titleImage} css={css`height:35px;  `}/>
                <span css={css`position:absolute; top:45%; transform:translateY(-50%); text-overflow: ellipsis; white-space:nowrap;`}>{currentDeck.contents.name}</span>
            </div>
            <span css={css`
                position: absolute;
                z-index: 2;
                /* white-space: nowrap; */
                width: 80px;
                height: 15px;
                top: 40px;
                left: 50%;
                transform: translateX(-50%);
                /* background: aliceblue; */
                word-spacing: 100vh;
                text-align: center;
                opacity: .5;
                @media screen and (min-width: 1080px) {
                    display: none;
                }
            `}>
                {open ? 'Collapse': 'Expand'} Deck
            </span>
            <div css={ControlsContainer}>

            <div css={css`  
              background: linear-gradient(180deg, rgba(225, 132, 223, 0.5) 0%, rgba(246, 15, 242, 0) 16.93%);
              height: 100%;

              `}>
                <DeckHeader deck={currentDeck.contents} />
                {currentDeck.contents.cards.length > 0 && <DeckCardTitle />}
                <div css={css`padding: 0 10px;
                    height: calc(100% - 100px);
                    overflow-y:scroll;
                    @media screen and (min-width: 1080px) {
                        overflow:hidden; 
                        :hover{
                            overflow-y:scroll;
                            padding-right: 5px;
                            } 
                    }
                        

                     `}
                     >


                    {renderCards(currentDeck.contents)}

                    {renderPlaceHolders(currentDeck.contents)}
                    <Button color="secondary" css={css`margin: 20px 0;`}>Deck Tutorials</Button>

                </div>
            </div>
        </div>
        </div>
    );
}

const DeckHeader = ({ deck }: any) => {

    const BigJune = styled.span`
    font-family: 'Junegull',serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 18px;
    /* identical to box height, or 75% */
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    text-transform: uppercase;

    `

    const getCountColor = () => {
        if (deck.cards.length === 12) return 'rgba(5, 255, 0, 1)';
        if (deck.cards.length > 12) return 'rgba(255, 0, 0, 1)';
        return '#F5F5F5;'

    }



    return (
        <div css={css`
            display: flex;
            height:90px;
            /* border:1px solid blue; */
            font-family: varela,serif;
            /* background: linear-gradient(180deg, rgba(246, 93, 217, 1), rgba(168, 41, 212, 0)); */
            div{
                display: flex;
                flex: 1;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap:10px;
                @media screen  and (min-width: 1080px) {
                    margin-top: 26px;
                }
          
            }
        `}>
            <div>
                <BigJune css={css`
                    color:${getCountColor()};
                `}>{deck.cards.length}/12</BigJune>
                <span>Cards</span>
            </div>
            <div>
                <BigJune>{deck.totalLinkPoints || 0}</BigJune>
                <span>Link Points</span>
            </div>
        </div>
    );
}


const DeckCard = ({ card }: any) => {
    const [loading, setLoading] = useState(false);
    const CardContainer = styled(motion.div)`
        padding:0 12px;
        background-color: blue;
        font-family: 'Junegull',serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 18px;
        margin: 4px 0;
        border: 2px solid white;
        clip-path: polygon(12px 0%, calc(100% - 12px) 0%, 100% 12px, 100% calc(100% - 12px), calc(100% - 12px) 100%, 12px 100%, 0% calc(100% - 12px), 0% 20%);
        /* identical to box height, or 100% */
        :before {
            content: "";
            position: absolute;
            top: 5px;
            left: -5px;
            width: 18px;
            height:2px;
            background-color: #F5F5F5;
            transform: rotate(128deg);
     
       
        }
        ::after {
            content: "";
            position: absolute;
            top: 5px;
            right: -5px;
            width: 18px;
            height:2px;
            background-color: #F5F5F5;
            transform: rotate(45deg);
     
       
        }
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        text-transform: uppercase;
        position: relative;
       
        &:hover > div:last-child {
            display: block;
        }  
        @media screen and (min-width: 1080px) {
            :hover {
                > div:first-of-type {padding-right:70px;}
            }
        } 
    `

    const removeFromDeck = useRecoilCallback(({ set, snapshot }) => async () => {
        setLoading(true);
        const currentDeck = await snapshot.getPromise(currentDeckSelector)
        const cards = currentDeck.cards.filter((c: any) => c.id !== card.id)
        const result = await DeckRepository.updateDeck({ ...currentDeck, cards })
        // alert('clickd')
        set(currentDeckAtom, result.deck)
        setLoading(false);
    }, [])


    return (
        <CardContainer  initial={{ opacity: 0, scale: 0.9, y:100,  }} animate={{ opacity: 1, scale: 1,  y:0, }} exit={{ opacity: 0, scale: 0.3, }}
        whileHover={{ scale: 1.01, }}
        >
            <div css={css`
            display: flex;
                flex: 1;
                height: 40px;
                background-color: black;
                align-items: center;
                justify-content:space-between;
                padding: 11px;
                cursor: pointer;
                position: relative;
                ::after {
                    content: "";
                    position: absolute;
                    bottom: 5px;
                    right: -18px;
                    width: 18px;
                    height:2px;
                    background-color: #F5F5F5;
                    transform: rotate(134deg);
            
            
                }
                ::before {
                    content: "";
                    position: absolute;
                    bottom: 5px;
                    left: -18px;
                    width: 18px;
                    height:2px;
                    background-color: #F5F5F5;
                    transform: rotate(223deg);
            
            
                }

            `}
             >
                {loading ? <div>Removing...</div> : (<><div>
                    {card.name}
                </div><div>
                        {card.lp}|{card.lvl}
                    </div></>)}
            </div>
            {!loading &&<div css={css`
                        display: none;
                        
                        position:absolute; right:15px; width:50px; min-width:0;
                        z-index: 2;
                        `}>

                <Button color="danger"  variant="icon" icon={<TiTimes /> as unknown as IconType}  onClick={()=> removeFromDeck()}/>
            </div>}
        </CardContainer>)
}

const CardPlaceholder = () => {
    const CardContainer = styled(motion.div)`
    font-family: 'Junegull',serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    margin: 4px 0;
    /* identical to box height, or 100% */

    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    position: relative;
    clip-path: polygon(12px 0%, calc(100% - 12px) 0%, 100% 12px, 100% calc(100% - 12px), calc(100% - 12px) 100%, 12px 100%, 0% calc(100% - 12px), 0% 20%);
`

return <CardContainer layout initial={{ opacity: 0, scale: 0.9, y:100 }} animate={{ opacity: 1, scale: 1,  y:0}} exit={{ opacity: 0, scale: 0.3 }}>
<div css={css`
display: flex;
    flex: 1;
    height: 40px;
    background-color: black;
    align-items: center;
    justify-content:space-between;
    padding: 11px;  
    position: relative;
`} />
</CardContainer>
}

const DeckCardTitle = () => {
    const CardContainer = styled(motion.div)`
    margin:0 20px;
        padding:0 22px;
        font-family: 'varela',serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        text-transform: uppercase;
        opacity: 0.5;
    `
    return (
        <CardContainer>
            <div css={css`
            display: flex;
                flex: 1;
                align-items: center;
                justify-content:space-between;
                padding: 0 11px;
              
            `}>
                <div>

                    card name
                </div>
                <div>
                    LP|LV
                </div>
            </div>
        </CardContainer>)
}
export default CollectorComponent;

/*
function useScroll(throttle = 100) {
	const scrollThrottle = React.useRef(throttle);
	const [scroll, setScroll] = React.useState({
	  hasScrolled: false,
	  top: 0,
	  bottom: 0,
	  percentage: 0
	});

	React.useEffect(() => {
	  scrollThrottle.current = throttle;
	}, [throttle]);

	React.useEffect(() => {
	  const updateScroll = () => {
		setScroll({
		  hasScrolled: true,
		  top: getScrollTop(),
		  bottom: getScrollBottom(),
		  percentage: getScrollPercentage()
		});
	  };
	  const scrollListener = _.throttle(updateScroll, scrollThrottle.current);

	  window.addEventListener("scroll", scrollListener);
	  return () => {
		window.removeEventListener("scroll", scrollListener);
	  };
	}, []);

	return scroll;
  }
  */

  export const getScrollPosition = () =>
  window.scrollY || document.documentElement.scrollTop;

  /*
export const getScrollTop = () =>
  (document.scrollingElement || document.documentElement).scrollTop;

export const getScrollBottom = () =>
  Math.abs(
    window.innerHeight + getScrollPosition() - document.body.scrollHeight
  );
  */

export const getScrollPercentage = () => {
  const scrollPosition = getScrollPosition();
  const scrollHeight =
    document.documentElement.scrollHeight -
    document.documentElement.clientHeight;
  const scrollTop = scrollPosition / scrollHeight || 0;

  return scrollTop * 100;
};
