/** @jsxImportSource @emotion/react */
import { AnimatePresence, motion } from "framer-motion";
import { css } from '@emotion/react';
import React, { createRef, MouseEventHandler, ReactNode, useEffect, useLayoutEffect, useRef, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import styled from '@emotion/styled'
import { useRecoilStateLoadable, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { currentDeckAtom, currentDeckSelector, decksSelector } from "store";
import { useOnClickOutside } from "usehooks-ts";

const clip = css`
    clip-path: polygon(15px 0%, calc(100% - 15px) 0%, 100% calc(50%), 100% calc(50% ), calc(100% - 15px) 100%, 15px 100%, 0% calc(50% ), 0% calc(50%));
`
const ClippedContainer = styled.div`
    box-sizing: border-box;
    color: white;
    background-color: #080620;
    height: 100%;
    align-items: center;
    display: flex;
    border: 2px solid rgba(255, 255, 255, 0.3);
    cursor: pointer;
    position: relative;
    flex-wrap: wrap;
    border-right: none;
    flex: 1;
    text-indent: 20px;
    margin-right:-14px;
    clip-path: polygon(0px 0%, calc(100% - 15px) 0%, 100% calc(50%), 100% calc(50% ), calc(100% - 15px) 100%, 0px 100%, 0% calc(50% ), 0% calc(50%));
    border-left: none;
    @media screen and (min-width: 1000px){
        ${clip}
        border: 2px solid rgba(255, 255, 255, 0.3);
        flex: 1;
        display: flex;
        width: 100%;
        margin-right: 0;
    }
    :before{
        content: "";
        position: absolute;
        height: 2px;
        width: 36px;
        background: rgba(255,255,255,0.3);
        top: 12px;
        right: -12px;
        transform: rotate(50deg);
    }
    :after{
        content: "";
        position: absolute;
        height: 2px;
        width: 36px;
        background: rgba(255,255,255,0.3);
        bottom: 12px;
        right: -11px;
        transform: rotate(128deg);
    }

`

const Container = styled.div`
    position: relative;
    width: 100%;
    max-height: 37px;
    height: 100%;

`




const DeckSelector = ({className}:any) => {
    const decks = useRecoilValueLoadable(decksSelector)
    const [opened, setOpened] = useState(false)
    const selected = useRecoilValueLoadable(currentDeckSelector)
    const setSelected = useSetRecoilState(currentDeckAtom)
    const targetRef = createRef<HTMLDivElement>();
    const [dimensions, setDimensions] = useState({ width:0, height: 0 });
    useEffect(() => {
        if (targetRef.current) {
            const {current }: any = targetRef
          setDimensions({
            width: current.offsetWidth,
            height: current.offsetHeight
          });
        }
      }, [targetRef.current && (targetRef.current as any).offsetWidth ]);
    const toggleOpened = () => {
        setOpened(!opened)
    }
    const select = (deck: any) => {
        setOpened(false)
        setSelected(deck)
    }
    const chevroncss= css`position: absolute; right:20px;`


    return (<Container ref={targetRef} className={className}>
       <ClippedContainer onClick={() => toggleOpened()} id="deck-selector">
           <span css={ css`
               text-overflow: ellipsis;
               overflow: hidden;
               height: 20px;
               white-space: nowrap;
               pointer-events: none;
                @media screen and (min-width: 1000px){
                    :after{
                     content: "";
                     position: absolute;
                     height: 2px;
                     width: 36px;
                     background: rgba(255,255,255,0.3);
                     bottom: 12px;
                     left: -14px;
                     transform: rotate(50deg);
                 }
                 :before{
                     content: "";
                     position: absolute;
                     height: 2px;
                     width: 36px;
                     background: rgba(255,255,255,0.3);
                     top: 12px;
                     left: -14px;
                     transform: rotate(128deg);
                 }
                }
               
           `}>
               {(selected.contents as any )?.name || decks.contents[0].name}
            </span>
            {opened ? <FaChevronUp css={chevroncss} /> : <FaChevronDown css={chevroncss} />}
        </ClippedContainer> 
        <AnimatePresence 
        // Disable any initial animations on children that
        // are present when the component is first rendered
        initial={false}
        // Only render one component at a time.
        // The exiting component will finish its exit
        // animation before entering component is rendered
        exitBeforeEnter={true}
        // Fires when all exiting nodes have completed animating out
        onExitComplete={() => null}
        >

        {opened && <List close={(e: { stopPropagation: () => void; }) => {
            setOpened(false)

        }
            } decks={decks.contents} onSelect={select} />}
        </AnimatePresence>

    </Container>)
}

const List = ({decks, onSelect, selected, close}:any) => {
    const  ref = useRef<HTMLUListElement>(null)
    useOnClickOutside(ref, ev => {
        const id = (ev.target as HTMLDivElement ).id
        if (id !== "deck-selector") {
            close()
        }
    })
    const renderDecks = () => {
        return decks.map((deck: any) => {
            return <li key={deck.id} onClick={() => onSelect(deck)}>{deck.name} </li>
        })
    }
    return <motion.div css={Deckscontainer} 
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        >
        <ul ref={ref}>
            {renderDecks()}
        </ul>
    </motion.div>
}




const Deckscontainer = css`
    position: absolute;
    top: calc(0% + 60px);
    left: calc(0% + 14px);
    width: fit-content;
    white-space: nowrap;
    padding: 0;
    box-sizing: border-box;
    min-width: 200px;
    /* min-width: 200px; */
    ul{
        flex-direction: column;
    }
    ul,li {
        background-color: #080620;
        list-style-type: none;
        padding: 0;
        margin: 0;
        width: fit-content;
        width: 100%;
        display: flex;        
    }
    li{
        width: calc(100% - 20px);
        text-align: center;
        padding:10px;
        cursor:pointer;
        border: 2px solid rgba(255, 255, 255, 0.2);
        height: 100%;
        :hover{
            background: linear-gradient(0deg, rgba(255,255,255,0.05) 30%, rgba(255,255,255,0.3) 100%); 
        }
    }
`


export default DeckSelector;

const dropIn = {
    hidden: {
        y: "-40px",
        opacity: 0,
    },
    visible: {
        y: "0",
        opacity: 1,
        // transition: {
        //     duration: 0.01,
        //     type: "spring",
        //     damping: 25,
        //     stiffness: 500,
        // },
    },
    exit: {
        y: "-40px",
        opacity: 0,
    },
};
