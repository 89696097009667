import { StyledTermWindow } from "./StyledTermWindow";

interface TermsWindowsProps {
  title: string;
  paragraphs: string[];
}

const TermsWindows = ({ title, paragraphs = [] }: TermsWindowsProps) => {
  const showList = (list: string[]) => {
    return (
      <ul className="terms-list">
        {list.map((item) => {
          return <li className="terms-list-item">{item}</li>;
        })}
      </ul>
    );
  };

  return (
    <StyledTermWindow>
      <div className="header">
        <h2 className="title">{title}</h2>
      </div>

      <div className="body">
        {paragraphs.map((term) => {
          if (typeof term === "string") {
            return (
              <p
                className="paragraph"
                dangerouslySetInnerHTML={{ __html: term }}
              />
            );
          }

          const { itemList } = term;
          return showList(itemList);
        })}
      </div>
    </StyledTermWindow>
  );
};

export default TermsWindows;
