/** @jsxImportSource @emotion/react */
import { AnimatePresence, motion } from "framer-motion";
import { ReactNode, useEffect } from "react";
import Backdrop from "./Backdrop";
import { css } from "@emotion/react";
import useScrollBlock from 'utils/scrollBlock'
import ReactDOM  from "react-dom";

const dropIn = {
    hidden: {
        y: "-100vh",
        opacity: 0,
    },
    visible: {
        y: "0",
        opacity: 1,
        transition: {
            duration: 0.1,
            type: "spring",
            damping: 25,
            stiffness: 500,
        },
    },
    exit: {
        y: "100vh",
        opacity: 0,
    },
};


const Modal = ({ handleClose, children, opened }: { handleClose: Function, children: ReactNode, opened: boolean }) => {
    const [blockScroll,allowScroll] = useScrollBlock()
    useEffect(() => {
        if(opened) return blockScroll()
        allowScroll()
    }, [opened])

    return  ReactDOM.createPortal(
        <AnimatePresence      
        // Disable any initial animations on children that
        // are present when the component is first rendered
        initial={false}
        // Only render one component at a time.
        // The exiting component will finish its exit
        // animation before entering component is rendered
        exitBeforeEnter={true}
        // Fires when all exiting nodes have completed animating out
        onExitComplete={() => null}
        >
            {opened && (
            <Backdrop onClick={() => handleClose()}>
                <motion.div
                    css={css`width: fit-content;`}
                    onClick={(e) => e.stopPropagation()}
                    className="modal orange-gradient"
                    variants={dropIn}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                >
                    {children}
                </motion.div>
            </Backdrop>)}
        </AnimatePresence>, 
        document.getElementById("portal") as HTMLElement
    );
};


export default Modal;