/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { motion } from "framer-motion";

import { AnimatePresence } from "framer-motion";
import { createRef, useRef, useState } from "react";
import { FaCheck, FaChevronDown, FaChevronUp } from "react-icons/fa";
import { atom, useRecoilCallback, useRecoilState, useRecoilValue, useRecoilValueLoadable } from "recoil";
import { collectionsSelector, filtersAtom } from "store";
import { useOnClickOutside } from "usehooks-ts";
import { MdStars } from "react-icons/md";

const clip = css`
    clip-path: polygon(15px 0%, calc(100% - 15px) 0%, 100% calc(50%), 100% calc(50% ), calc(100% - 15px) 100%, 15px 100%, 0% calc(50% ), 0% calc(50%));
`

const ClippedContainer = styled.div`
    box-sizing: border-box;
    color: white;
    height: 100%;
    max-height: 37px;
    align-items: center;
    display: flex;
    cursor: pointer;
    position: relative;
    flex-wrap: wrap;
    border-right: none;
    flex: 1;
    text-indent: 20px;
    margin-top: 20px;
    @media screen and (max-width: 1000px){
            justify-content: center;
    }
    @media screen and (min-width: 1000px){
        margin-top:0;
        background-color: #080620;
        ${clip}
        border: 2px solid rgba(255, 255, 255, 0.3);
        flex: 1;
        display: flex;
        width: 100%;
        margin-right: 0;
    
    :before{
        content: "";
        position: absolute;
        height: 2px;
        width: 36px;
        background: rgba(255,255,255,0.3);
        top: 12px;
        right: -12px;
        transform: rotate(50deg);
    }
    :after{
        content: "";
        position: absolute;
        height: 2px;
        width: 36px;
        background: rgba(255,255,255,0.3);
        bottom: 12px;
        right: -11px;
        transform: rotate(128deg);
    }
    }
`



const FilterAndSort = ({ }: any) => {
    return <div css={css`display:flex; flex:1; justify-content:flex-end; align-items: center; gap:50px;
        @media screen and (min-width: 1000px){
        gap:0;
        }
    
    `}>
        <div css={css`flex:1; height:100%; display:flex; justify-content:center; align-items:center;z-index:2;; 
        

    @media screen and (min-width: 1000px){

        padding-left:20px;
    }
    
        `}>
            <CollectionsFilter />
        </div>
        {/* <div css={css`flex:1; height:100%; min-height: 30px; display:flex; justify-content:center; align-items:center;
        @media screen and (min-width: 1444px){

            margin-left:-30px; z-index:20;
        }
         `}>
            <Filters />
        </div> */}
        <div css={css`flex:1; height:100%; min-height: 30px; display:flex; justify-content:center; align-items:center;
        @media screen and (min-width: 1000px){
            margin-left:-30px; z-index:1;
            }`}>
            <SortFilter />

        </div>
    </div>

}

const Container = styled.div`
    width: 100%;
    max-height: 37px;
    height: 100%;
    @media screen and (min-width: 700px){
        position: relative;
    }
`

const chevroncss = css`
    margin-left: 10px;
@media screen and (min-width: 1000px){
    position: absolute; right:20px;
}
`
const CollectionsFilter = ({ className }: any) => {
    const Collections = useRecoilValueLoadable(collectionsSelector);
    const filtersData = useRecoilValue(filtersAtom);
    const [opened, setOpened] = useState<boolean>();
    const targetRef = createRef<HTMLDivElement>();

    const handleSelect = useRecoilCallback(({ set, snapshot }) => async (collection: any) => {

        const filtersState = await snapshot.getPromise(filtersAtom);
        const data = { ...filtersState, filters: { ...filtersState.filters, collection: collection } };
        set(filtersAtom, data);
        setOpened(false)
    }
    )

    if (Collections.state === "loading") return <div>Loading</div>


    return <Container ref={targetRef} className={className}>
        <ClippedContainer onClick={() => setOpened(!opened)} id="collections-selector" >
            <span css={css`
               text-overflow: ellipsis;
               overflow: hidden;
               height: 20px;
               white-space: nowrap;
               pointer-events: none;
 
                @media screen and (min-width: 1000px){
                    text-indent: 30px;
                    :after{
                     content: "";
                     position: absolute;
                     height: 2px;
                     width: 36px;
                     background: rgba(255,255,255,0.3);
                     bottom: 12px;
                     left: -14px;
                     transform: rotate(50deg);
                 }
                 :before{
                     content: "";
                     position: absolute;
                     height: 2px;
                     width: 36px;
                     background: rgba(255,255,255,0.3);
                     top: 12px;
                     left: -14px;
                     transform: rotate(128deg);
                 }
                }
               
           `}>

                <MdStars css = {[css`vertical-align:bottom;`,filtersData.filters.collection && css`color:purple;`]}/> { "Collection"}

            </span>
            {opened ?
                <FaChevronUp css={chevroncss} /> :
                <FaChevronDown css={chevroncss} />}
        </ClippedContainer>

        <AnimatePresence
            // Disable any initial animations on children that
            // are present when the component is first rendered
            initial={false}
            // Only render one component at a time.
            // The exiting component will finish its exit
            // animation before entering component is rendered
            exitBeforeEnter={true}
            // Fires when all exiting nodes have completed animating out
            onExitComplete={() => null}
        >

            {opened && <CollectionList selected={filtersData.filters.collection} close={(e: { stopPropagation: () => void; }) => {
                setOpened(false)

            }
            } collections={Collections.contents} onSelect={handleSelect} />}
        </AnimatePresence>

    </Container>
}

export default FilterAndSort


const CollectionList = ({ collections, onSelect, selected, close }: any) => {
    const ref = useRef<HTMLUListElement>(null)

    useOnClickOutside(ref, ev => {
        const id = (ev.target as HTMLDivElement).id
        if (id !== "collections-selector") {
            close()
        }
    })
    const renderCollections = () => {
        return collections.map((collection: any) => {
            return <li key={collection} onClick={() => onSelect(collection)}>{collection} {collection === selected && <FaCheck />}</li>
        })
    }
    return <motion.div css={dropDownListcontainer}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
    >
        <ul ref={ref}>
            {renderCollections()}
        </ul>
    </motion.div>
}

const dropDownListcontainer = css`
    position: absolute;
    top: calc(0% + 60px);
    left: 0;
    width: fit-content;
    white-space: nowrap;
    padding: 0;
    box-sizing: border-box;
    min-width: 200px;
    z-index:2;
    /* min-width: 200px; */
    ul{
        flex-direction: column;
    }
    ul,li {
        background-color: #080620;
        list-style-type: none;
        padding: 0;
        margin: 0;
        width: 100%;
        display: flex;        
    }
    li{
        width: calc(100% - 20px);
        text-align: center;
        padding:10px;
        cursor:pointer;
        border: 2px solid rgba(255, 255, 255, 0.2);
        height: 100%;
        :hover{
            background: linear-gradient(0deg, rgba(255,255,255,0.05) 30%, rgba(255,255,255,0.3) 100%); 
        }
    }

    @media screen and (max-width: 700px){
        width: calc(100% - 4px);
        top: 250px;


    }
`


const dropIn = {
    hidden: {
        y: "-40px",
        opacity: 0,
    },
    visible: {
        y: "0",
        opacity: 1,
        // transition: {
        //     duration: 0.01,
        //     type: "spring",
        //     damping: 25,
        //     stiffness: 500,
        // },
    },
    exit: {
        y: "-40px",
        opacity: 0,
    },
};

const sortOptions = [{
    name: "NAME",
    attribute: "name",
},
{
    name: "LEVEL",
    attribute: "lvl",
},
{
    name: "RARITY",
    attribute: "rarity",
},
{
    name: "Combat ATK",
    attribute: "atk",
},
{
    name: "Support ATK",
    attribute: "atkS",
},
{
    name: "Experience Points",
    attribute: "ep",
},
{
    name: "Link Points",
    attribute: "lp",
},
{
    name: "Combat HP",
    attribute: "hp",
},
{
    name: "Support HP",
    attribute: "hpS",
},
{
    name: "Vapor points",
    attribute: "vp",


},
{
    name: "Shuffle points",
    attribute: "shufflePoints",
},
{
    name: "Evolution",
    attribute: "evolution",

},
{
    name: "number",
    attribute: "number",

}
]

const SortFilter = ({ className }: any) => {
    const [filters, setFilters] = useRecoilState(filtersAtom);
    const [opened, setOpened] = useState<boolean>();
    const targetRef = createRef<HTMLDivElement>();
    const { sortBy } = filters;
    const handleSort = (selection: { name: string, attribute: string }) => {
        if (filters.sortBy.attribute === selection.attribute) {
            const type = filters.sortBy.type === "asc" ? "desc" : "asc"

            setFilters({
                ...filters,
                sortBy: {
                    attribute: selection.attribute,
                    type: filters.sortBy.type === "asc" ? "desc" : "asc"
                }
            })
        } else {
            setFilters({
                ...filters,
                sortBy: {
                    attribute: selection.attribute,
                    type: "asc"
                }
            })
        }
        setOpened(false)
    }

    return <Container ref={targetRef} className={className}>
        <ClippedContainer onClick={() => setOpened(!opened)} id="sort-by-selector" >
            <span css={css`
               text-overflow: ellipsis;
               overflow: hidden;
               height: 20px;
               white-space: nowrap;
               pointer-events: none;
               text-transform: uppercase;
               text-overflow: ellipsis;
                @media screen and (min-width: 1000px){
                    text-indent: 35px;
                    :after{
                     content: "";
                     position: absolute;
                     height: 2px;
                     width: 36px;
                     background: rgba(255,255,255,0.3);
                     bottom: 12px;
                     left: -14px;
                     transform: rotate(50deg);
                 }
                 :before{
                     content: "";
                     position: absolute;
                     height: 2px;
                     width: 36px;
                     background: rgba(255,255,255,0.3);
                     top: 12px;
                     left: -14px;
                     transform: rotate(128deg);
                 }
                }
               
           `}>{sortBy.attribute === '' ? 'Sort by' : sortBy.attribute} {sortBy.attribute && sortBy.type}</span>
            {opened ?
                <FaChevronUp css={chevroncss} /> :
                <FaChevronDown css={chevroncss} />}
        </ClippedContainer>

        <AnimatePresence
            // Disable any initial animations on children that
            // are present when the component is first rendered
            initial={false}
            // Only render one component at a time.
            // The exiting component will finish its exit
            // animation before entering component is rendered
            exitBeforeEnter={true}
            // Fires when all exiting nodes have completed animating out
            onExitComplete={() => null}
        >

            {opened && <SortList close={(e: { stopPropagation: () => void; }) => {
                setOpened(false)

            }
            } sortOptions={sortOptions} onSelect={handleSort}  selected={sortBy}/>}
        </AnimatePresence>

    </Container>
}

const SortList = ({ sortOptions, onSelect, selected, close }: any) => {

    const ref = useRef<HTMLUListElement>(null)
    useOnClickOutside(ref, ev => {
        const id = (ev.target as HTMLDivElement).id
        if (id !== "sort-by-selector") {
            close()
        }
    })
    const renderSortOptions = () => {
        return sortOptions.map((sortOption: any) => {
            return <li key={sortOption.attribute} onClick={() => onSelect(sortOption)}>{sortOption.name} {selected.attribute === sortOption.attribute && <FaCheck/>}</li>
        })
    }
    return <motion.div css={dropDownListcontainer}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
    >
        <ul ref={ref}>
            {renderSortOptions()}
        </ul>
    </motion.div>
}



const Filters = ({ className }: any) => {
    const filters = useRecoilState(filtersAtom);
    const [opened, setOpened] = useState<boolean>();
    const targetRef = createRef<HTMLDivElement>();
    // if(Collections.state === "loading") return <div>Loading</div>



    return <Container ref={targetRef} className={className}>
        <ClippedContainer onClick={() => setOpened(!opened)} id="collections-selector" css={css`
        `}>
            <span css={css`
               text-overflow: ellipsis;
               overflow: hidden;
               height: 20px;
               white-space: nowrap;
               pointer-events: none;
                @media screen and (min-width: 1000px){
                    text-indent: 35px;
                    :after{
                     content: "";
                     position: absolute;
                     height: 2px;
                     width: 36px;
                     background: rgba(255,255,255,0.3);
                     bottom: 12px;
                     left: -14px;
                     transform: rotate(50deg);
                 }
                 :before{
                     content: "";
                     position: absolute;
                     height: 2px;
                     width: 36px;
                     background: rgba(255,255,255,0.3);
                     top: 12px;
                     left: -14px;
                     transform: rotate(128deg);
                 }
                }
          
           `}>

                Filter by

            </span>
            {opened ?
                <FaChevronUp css={chevroncss} /> :
                <FaChevronDown css={chevroncss} />}
        </ClippedContainer>

        <AnimatePresence
            // Disable any initial animations on children that
            // are present when the component is first rendered
            initial={false}
            // Only render one component at a time.
            // The exiting component will finish its exit
            // animation before entering component is rendered
            exitBeforeEnter={true}
            // Fires when all exiting nodes have completed animating out
            onExitComplete={() => null}
        >
            {/* 
            {opened && <List close={(e: { stopPropagation: () => void; }) => {
                setOpened(false)

            }
                } collections={Collections.contents} onSelect={() => {}} />} */}
        </AnimatePresence>

    </Container>
}