const stage = process.env.REACT_APP_STAGE || 'zacatecas'

type ConfigServiceHostTypes = 'zacatecas' | 'staging' | 'prod' | 'demo' | 'local' | 'local|zacatecas'
export abstract class ConfigService {

    static walletForm:string = 'https://form.jotform.com/223055627921859'
    static playURL:string = ''

    static getPlayURL() {
        if(ConfigService.isHost('local')){
            if(window.location.pathname.startsWith('/deck-select') && ConfigService.playURL !== ''){
                return ConfigService.playURL
            }
            return '/deck-select'
        }
        else if(ConfigService.isHost('zacatecas')){
            const wallet = localStorage.getItem('wallet')
            if(wallet){
                if(window.location.pathname.startsWith('/deck-select') && ConfigService.playURL !== ''){
                    return ConfigService.playURL
                }
                return '/deck-select'
            }
            return ConfigService.walletForm
        }
        else if(ConfigService.isHost('prod')){
            const wallet = localStorage.getItem('wallet')
            if(wallet) return 'https://zacatecas.chainreaction.gg/deck-select'
            return ConfigService.walletForm
        }
        return ConfigService.walletForm
    }

    static goToPlay(){
        window.open(ConfigService.getPlayURL(),'_self')
    }

    static getQuery(key:string):string|undefined{
        const urlSearchParams = new URLSearchParams(window.location.search)
        const params = Object.fromEntries(urlSearchParams.entries())
        return params && params[key] ? params[key] : undefined
    }

    static async checkWallet(){
        const {origin,pathname} = window.location
        if(!ConfigService.isHost('zacatecas')) return
        let wallet:string|null|undefined = ConfigService.getQuery('wallet')
        if(wallet){
            const url = `${origin}${pathname}`
            localStorage.setItem('wallet',wallet)
            if(ConfigService.isHost('zacatecas')) await ConfigService.postWalletToMain(wallet)
            return window.location.replace(url)
        }
        wallet = localStorage.getItem('wallet')
        if(!wallet){
            if(ConfigService.isHost('zacatecas')) return window.location.replace(ConfigService.walletForm)
            return window.location.replace(`${origin}/`)
        }
    }

    static walletListener(){
        const win:any = window
        window.addEventListener('message',(ev:MessageEvent<any>)=>{
            if(!ev.data || ev.data.key !== 'wallet') return
            localStorage.setItem('wallet',ev.data.value)
        })
        win['parent'].postMessage('wallet','*')
    }

    static async postWalletToMain(wallet:string) {
        await new Promise((res:any)=>{
            const ifr = document.createElement('iframe')
            window.addEventListener('message',(ev:MessageEvent<any>)=>{
                if(!ev.data || ev.data !== 'wallet') return
                ifr.contentWindow?.postMessage({key:'wallet',value:wallet},'*')
                res()
            })
            ifr.src = 'https://chainreaction.gg/iframe'
            ifr.style.width = '0'
            ifr.style.height = '0'
            ifr.style.display = 'none'
            document.body.appendChild(ifr)
        })
    }

    static isHost(key:ConfigServiceHostTypes){
        const host = window.location.host
        switch(key){
            case 'zacatecas':
                return host.startsWith('zacatecas.')
            case 'staging':
                return host.startsWith('staging.')
            case 'prod':
                return host.startsWith('chainreaction.')
            case 'demo':
                return host.startsWith('demo.')
            case 'local|zacatecas':
                return !host.startsWith('staging.') && !host.startsWith('chainreaction.') && !host.startsWith('demo.')
            default:
                return !host.startsWith('zacatecas.') && !host.startsWith('staging.') && !host.startsWith('chainreaction.') && !host.startsWith('demo.')
        }
    }
}

const getConfig = (): configService => {
    switch(stage){
        case 'prod': return prod
        case 'staging': return staging
        case 'zacatecas': return zacatecas
        case 'local': return local
        default: return prod
    }
}

const base:configService = {
    stage:'prod',
    litePaperURL: '/papers/CHAIN_R_LITEPAPER.pdf',
    ruleBookURL: '/papers/QUICK_MANUAL_CHAIN_REACTION.pdf',
    hostURL: 'https://chainreaction.gg',
    user: 'userId1234',
    token: window.atob('ZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SmphR1ZqYXlJNmRISjFaU3dpWVhCd2JHbGpZWFJwYjI1VWIydGxiaUk2SW1Gd2NHeHBZMkYwYVc5dUlpd2lhV0YwSWpveE5qUTFNVEkxTVRZeExDSmxlSEFpT2pJeE5qTTFNalV4TmpGOS5sNGxRTWhDdG14Y1Y4YldUbDc3Uy1KRzJTenpjZTBGYVA4OGhHSklfWVg4'),
    playURL: ConfigService.getPlayURL(),
}

function getHost(host:string){
    if(window.location.host.indexOf('chainreaction.gg')>-1) return ''
    return host
}

const prod: configService = {
    ...base,
    stage: 'prod',
    hostURL: getHost('https://chainreaction.gg')
}

const staging: configService = {
    ...base,
    stage: 'staging',
    hostURL: getHost('https://staging.chainreaction.gg')
}

const zacatecas: configService = {
    ...base,
    stage: 'zacatecas',
    hostURL: getHost('https://zacatecas.chainreaction.gg')
}

const local: configService = {
    ...base,
    stage: 'local',
    hostURL: 'http://localhost:4001'
}

type configService = {
    stage:string
    litePaperURL: string
    ruleBookURL: string
    hostURL: string
    user: string
    token: string
    playURL: string
}

export default getConfig
