/** @jsxImportSource @emotion/react */
// noinspection CssUnknownProperty,CssInvalidHtmlTagReference

import { css, Global, } from '@emotion/react';
import junegull from './assets/fonts/junegull/junegull-rg.ttf';
import north_zone from './assets/fonts/north_zone/NorthZone.otf'
import varela from './assets/fonts/Varela/Varela-Regular.ttf'
import varelaRound from './assets/fonts/Varela_Round/VarelaRound-Regular.ttf'
import emotionNormalize from 'emotion-normalize'
// import blob from './assets/svg/blob.svg';

import { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from 'pages/Home';
import DeckBuilder from 'pages/DeckBuilder';
import TermsAndConditions from 'pages/TermsAndConditions';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import DeckSelect from "./pages/DeckSelect";
import {ConfigService} from "./config.service";
import Blank from "./pages/Blank";
import Redirect from "./pages/Redirect";
/*
const BlobBackground = ()=> <div css={css`
  width:100%;
  height:100vh;
  background-color: #0C092D;
  position: fixed;
  top: 0;
  z-index: -1;
`}
>
  <div css={css`
    width: 100%;
    height: 100%;
    position: relative;
  `}>

    <img src={blob} css={css`
      position: absolute;
      top:-10%;
      right:-20%;
      width:60%;
      opacity: .5;
      filter: blur(200px);
    `}/>
    <img src={blob} css={css`
      position: absolute;
      bottom:-10%;
      left:-30%;
      width:60%;
      opacity: .5;
      filter: blur(200px);
    `}/>
  </div>
  </div>
*/

let routes:any[] = [
    { path:'/', element:<Home /> },
    { path: '/iframe', element:<Blank /> },
    { path:'/deck-builder', element:<DeckBuilder/> },
    { path:'/deck-select', element:<DeckSelect /> },
    { path:'/terms-and-conditions', element:<TermsAndConditions /> },
    { path:'/privacy', element:<PrivacyPolicy /> },
    { path:'*', element:<Redirect /> }
]
const remove:string[] = []
if(!ConfigService.isHost('local')) remove.push('/deck-builder')
if(ConfigService.isHost('prod')) remove.push('/deck-select')
if(ConfigService.isHost('zacatecas')){
    routes = [
        { path:'/deck-select', element:<DeckSelect /> },
        { path:'*', element:<Redirect /> }
    ]
}
routes = routes.filter(route=>!remove.includes(route.path))

function App() {
    return (
        <div css={css`position:relative;`}>

      <Global styles={css`
        ${emotionNormalize}
          @font-face {
            font-family: 'Junegull';
            src: url(${junegull});
          }
          @font-face {
            font-family: 'North Zone';
            src: url(${north_zone});
          }
          @font-face {
            font-family: 'Varela';
            src: url(${varela});
          }
          @font-face {
            font-family: 'Varela Round';
            src: url(${varelaRound});
          }
          html {
	        scroll-behavior: smooth;
          }

          body{
              background-color: #0C092D;
          }
          h1{
            font-family: 'Junegull';
            font-style: normal;
            font-weight: 400;
            font-size: 50px;
            line-height: 50px;
            align-items: center;
            letter-spacing: -0.02em;
            text-transform: uppercase;
            color: #FFFFFF;
            text-align: center;
            width: 100%;
            @media screen  and (min-width: 600px) {
              font-size: 70px;
              line-height: 70px;
            }
            @media screen  and (min-width: 600px) {
              font-size: 80px;
              line-height: 80px;
              width: fit-content;
            }
          }
          h2{
            font-family: 'Junegull';
            font-style: normal;
            font-weight: 400;
            font-size: 25px;
            line-height: 25px;
            align-items: center;
            letter-spacing: -0.02em;
            text-transform: uppercase;
            color: #FFFFFF;
            text-align: center;
            width: 100%;
            @media screen  and (min-width: 600px) {
              font-size: 30px;
              line-height: 30px;
            }
            @media screen  and (min-width: 600px) {
              font-size: 50px;
              line-height: 50px;
              width: fit-content;
            }
          }
            h3{
              font-size: 18px;
              padding:20px;
              font-family: 'Varela Round';
              font-style: normal;
              font-weight: 400;
              color: white;
              
              @media screen  and (min-width: 600px) {
                font-size: 24px;
                line-height: 34px;
            }
              @media screen  and (min-width: 1080px) {
                padding: 0;
            }

          
          }
          button{
            border-radius: 15px;
            border: none;
            z-index:1;
            cursor: pointer;
            font-family: 'Junegull';
            font-style: normal;
            font-weight: 400;
            font-size: 21px;
            line-height: 21px;
            letter-spacing: 0.03em;
            text-transform: uppercase;
            color: #F5F5F5;
            text-shadow: 0px 0 2px #000;
          }
/* Scroll bar stylings */
        *::-webkit-scrollbar {
            width: 5px;
            height: 10px;
          }

          /* Track */
          *::-webkit-scrollbar-track {
            background: var(--lightestgrey); 
          }
          
          /* Handle */
        *::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background: linear-gradient(180deg, #FF9FC8 0%, rgba(255, 0, 249, 0) 100%), #FF00F9;
            opacity: 0.9;
            /* border-radius: 30px; */
          }

          /* Handle on hover */
          *::-webkit-scrollbar-thumb:hover {
            background: #555; 
          }
      `} />
            {/* <BlobBackground/> */}

            <BrowserRouter>
                <Routes>
                    { routes.map(route=>(
                        <Route key={`route-${route.path}`} path={route.path} element={route.element} />
                    )) }
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}
