/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import gameplayImage from '../assets/images/sections/gameplay.png'
import { HeroContainer, HeroSection } from './Hero';
import { GradientHeading } from './Hero';
import { Button } from './Button';
import  { useInView} from 'react-intersection-observer'
import { useEffect } from 'react';
import { useAnimation, motion } from 'framer-motion';
import getConfig from "config.service";

const GamePlay = () => {

    const [ref, inView] = useInView({
        threshold: 0.5,
    })

    const leftAnimation = useAnimation();
    const rightAnimation = useAnimation();

    useEffect(() => {
        if(inView){
            leftAnimation.start({
                opacity: 1,
                x: 0,
                transition: {
                    duration: .5,
                    ease: 'easeOut'
                }
            }).then()
            rightAnimation.start({
                opacity: 1,
                y: 0,
                transition: {
                    duration: .5,
                    ease: 'easeOut'
                }
            }).then()
        } else {
            leftAnimation.start({
                opacity: 0,
                x: -100,
                transition: {
                    duration: .5,
                    ease: 'easeOut'
                }
            }).then()
            rightAnimation.start({
                opacity: 0,
                y: 100,
                transition: {
                    duration: .5,
                    ease: 'easeOut',
                    delay: .25
                }
            }).then()
        }
    },[inView,leftAnimation,rightAnimation])






    return <HeroContainer ref={ref} id="gameplay" css={css`
    @media screen and (min-width: 1444px) {
        
        height:100vh;
    }
    `}>
        <HeroSection revert css={css`margin-top:100px;`}>
            <div css={{ flex: 1, display: 'flex' }}>
                <motion.img src={gameplayImage} css={css`max-width:100%; margin:auto;`} animate={leftAnimation}/>
            </div>
            <motion.div css={css`
            flex:1;
            justify-content: center;
            align-items: center;
            display:flex;

          `} animate={rightAnimation}>

                <div css={css`
             justify-content: center;
            align-items: flex-start;
            margin: auto;
            display: flex;
            flex-direction: column;
            gap: 20px; 
            max-width: 70%;
            `}>


                    <GradientHeading text="Learn and play" />
                    <h1 css={css`
                       margin:0 auto;
                        @media screen and (min-width: 1444px) {
                            margin:0;
                        }
                    
                    `}>
                        gameplay
                    </h1>
                    <h3 css={css`
   
                     margin-top:39px;
                     margin-bottom:54px;
                     `}>
                        Arrange your Front and Support lines for the fight and get Link Points to win!
                    </h3>
                    <div css={css`
                display: flex;
                justify-content:center;
                width: 100%;
                @media (min-width: 1400px) {
                    justify-content:space-between;
                }
            `}>

                        <Button href={getConfig().ruleBookURL} target={'_blank'} css={{ width:'fit-content'}}>see Rulebook</Button>
                    </div>
                </div>
            </motion.div>

        </HeroSection>
    </HeroContainer>
}
export default GamePlay;

