/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { IconType } from 'react-icons';
import { BsDiscord, BsMedium, BsTwitter, BsTelegram } from 'react-icons/bs';
import grid from '../assets/images/gridbackground.png';
import { Button } from './Button';
import { HeroContainer, HeroSection } from './Hero';
import Marquee from './Marquee';
import getConfig, {ConfigService} from "../config.service";
const containersStyle = css`
        height: 50%;
        width: 100%;
        position: absolute;
        bottom: 0;
    `

const Play = () => {
    return <HeroContainer >
        <HeroSection css={
            css``
        }>
            <div css={css`
           color: white;
           font-size: 40px;
           justify-content: flex-start;
           align-items: center;
           display:flex;
           width: 100%;
           font-family: Junegull,serif;
           flex-direction: column;
           height:100%;
           /* padding-top: 200px; */
           margin-top: 200px;
           background: linear-gradient(0deg, #D400CF 50%, rgba(212, 0, 207, 0) 100%);;
           position: relative;
       `}>

                <Marquee text="get started right now" />

                <div css={css`
                    ${containersStyle}
                    `}>
                        <img alt={'grid'} src={grid} css={{ width: "100%", height:'100%' }} />
                </div>
                {/* <div
                    css={css`
                        height:100%;
                        width: 100%;
                        z-index:1;
                        top:0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                `}
                > */}
                    <div css={css`
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                    flex:1;
                    margin-bottom: 100px;
                `}>

                        {
                            <Button
                                onClick={()=>ConfigService.goToPlay()}
                                color={"secondary"} css={css`
                            width: fit-content;
                            margin-bottom: 50px;
                        `}
                        >PLAY</Button>
                        }

                        <div css={css`
                          background: #05031C;
                          font-family: 'Junegull', serif;
                          font-style: normal;
                          font-weight: 400;
                          font-size: 28px;
                          line-height: 32px;
                          display: flex;
                          align-items: center;
                          max-width: 70%;
                          width: 100%;
                          justify-content: center;
                          color: #F5F5F5;
                          text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
                          flex-direction: column;
                          text-align: center;
                          gap: 20px;
                          padding: 20px;
                          z-index: 2;
                          @media screen and (min-width: 1080px) {
                            flex-direction: row;
                            justify-content: space-between;
                            max-width: 1000px
                          }
                        `}>Join Our Community!

                            <div css={css`
                            flex-direction:row;
                            display: inline-flex;
                            gap: 40px;
                            flex-wrap: wrap;
                            align-items: center;
                            justify-content: center;
                            max-width:250px;
                            @media screen and (min-width: 1080px) {
                                display: flex;
                                gap:10px;
                                max-width:100%
                            }
                    `}>
                                <Button href={'https://discord.gg/52Mm2wASSu'} target={'_blank'} color='secondary' variant={'icon'} icon={<BsDiscord /> as unknown as IconType} />
                                <Button href={'https://twitter.com/ChainReactionGG'} target={'_blank'} color='secondary' variant={'icon'} icon={<BsTwitter /> as unknown as IconType} />
                                <Button href={'https://t.me/chainreactionnft'} target={'_blank'} color='secondary' variant={'icon'} icon={<BsTelegram /> as unknown as IconType} />
                                <Button href={'https://medium.com/@ChainReactionGG'} target={'_blank'} color='secondary' variant={'icon'} icon={<BsMedium /> as unknown as IconType} />
                            </div>
                        </div>
                    </div>

                {/* </div> */}
            </div>
        </HeroSection>
    </HeroContainer>
}
export default Play;

