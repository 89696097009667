/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import { EffectCoverflow, Pagination, Autoplay } from "swiper";
import { HeroContainer } from './Hero';
import gridBackground from '../assets/images/hero-bg.jpg';
import Marquee from './Marquee';
import card1 from '../assets/images/cards/01.png';
import card2 from '../assets/images/cards/02.png';
import card3 from '../assets/images/cards/03.png';
import card4 from '../assets/images/cards/04.png';
import card5 from '../assets/images/cards/05.png';
import card6 from '../assets/images/cards/06.png';
import card7 from '../assets/images/cards/07.png';
// import card8 from '../assets/images/cards/Mirracard.png';
import card9 from '../assets/images/cards/08.png';
import card10 from '../assets/images/cards/09.png';

const cards = [card1, card2, card3, card4, card5, card6, card7, card9, card10];
const Collections = () => {
    return <HeroContainer id={"collections"} css={css`
    @media screen and (min-width: 1444px) {
        
        height:100vh;
    }
    `}>
        <div css={css`
           color: white;
           font-size: 40px;
           justify-content: space-around;
           align-items: center;
           display:flex;
           width: 100%;
           font-family: Junegull,serif;
           flex-direction: column;
           position: relative;
           .swiper-slide{
               width: fit-content;
           }
       `}>
           <img alt={'grid background'} src={gridBackground} css={{position:'absolute', width: "100%", opacity:0.2 }} />
           {/* <h1 css={css`
                font-weight: 400;
                font-size: 192.426px;
                line-height: 181px;
                z-index:1;
                margin: 0;
           `}>

            OUR COLLECTION
           </h1> */}
            <Marquee text="our collection" css={css`top:20px;`}/>
            <Swiper
                effect={"coverflow"}
                loop={true}
                autoplay={true}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                // spaceBetween={20}
                coverflowEffect={{
                    rotate: 10,
                    stretch: 100,
                    depth: 100,
                    modifier: 1,
                    slideShadows: false
                }}
                pagination={true}
                modules={[EffectCoverflow, Pagination, Autoplay]}
                className="mySwiper"
            >
                {cards.map((card) => (

                <SwiperSlide key={card}>
                    <img alt={'card'} src={card} css={css`display: block;width: 100%; `} />
                </SwiperSlide>
                ))}


            </Swiper>
                {/* <Button css={css`
                    width: fit-content;
                `} >
                    VISIT MARKETPLACE
                </Button > */}
        </div>
    </HeroContainer>
}
export default Collections;

