/** @jsxImportSource @emotion/react */
import { ImCross, ImPlus } from "react-icons/im"
import { TbDots } from "react-icons/tb"
import {css} from '@emotion/react'
import { useState, useRef } from "react"
import { useOnClickOutside } from "usehooks-ts"

const DeckActions = ({newDeck,deleteDeck}:{newDeck:Function,deleteDeck:Function}) => {
    const [open,setOpen] = useState(false)
    const  ref = useRef<HTMLUListElement>(null)

    const handleClickOutside = () => {
        setOpen(false)
      }
    
      useOnClickOutside(ref, ev => {
        const target = (ev.target as HTMLDivElement )

        if (![target.id,(target.parentNode as HTMLDivElement).id].includes("deck-actions")) {
            handleClickOutside()
        }
    })




    return    <div css={css`
                width:100px; 
                display:flex; 
                justify-content:flex-end; 
                align-items:center; 
                position: relative; 
                max-height: 37px;
                height: 100%;
                @media screen and (min-width: 1000px){
                    width: fit-content;
                    white-space: nowrap;
                    flex:1
                 }
    `}>
    <div css={css`
        box-sizing: border-box;
        background-color: #080620;
        width:100%;
        height: 100%;
        display:flex;
        justify-content:center;
        align-items: center;
        align-self: flex-end;
        clip-path:polygon(0px 0%, calc(100% - 15px) 0%, 100% calc(50%), 100% calc(50% ), calc(100% - 15px) 100%, 0px 100%, 0% calc(50% ), 0% calc(50%));
        border: 2px solid rgba(255,255,255,0.3);
        border-left: 0;
        cursor: pointer;
        :before{
        content: "";
        position: absolute;
        height: 2px;
        width: 36px;
        background: rgba(255,255,255,0.3);
        top: 12px;
        right: -12px;
        transform: rotate(50deg);
    }
    :after{
        content: "";
        position: absolute;
        height: 2px;
        width: 36px;
        background: rgba(255,255,255,0.3);
        bottom: 12px;
        right: -11px;
        transform: rotate(128deg);
    }
        @media screen and (min-width: 1000px){
            display:none;
        }
    `} onClick={() => setOpen(!open)} id="deck-actions">
        
       <TbDots/>
    </div>
    <ul 
    ref={ref}
    
    css={css`
        display: ${open ? 'flex' : 'none'};
        font-family: junegull;
        position: absolute; 
        top:40px; 
        right:-50%;  
        width:fit-content; 
        margin:0; 
        padding:0; 
        list-style-type: none;
        height: 100%;
        flex-direction: column;
        width:fit-content;
        z-index: 2;
        @media screen and (min-width: 1000px){
            box-sizing: border-box;
            height: 100%;
            width: 100%;
            flex-direction: row; 
            position: unset;
            top: auto;
            right: auto;
            display: flex;
            flex:1;
        
        }
        
        li{
            cursor: pointer;
            margin-left: -30px;
            background-color: #080620e1;
            height: 100%;
            display: flex;
            flex:1;
            justify-content: space-around;
            align-items: center;
            border: 2px solid rgba(255,255,255,0.3);
            position: relative;
            padding: 20px;
            width: 160px;
            :hover{
                background-color: #080620e1;
                background: linear-gradient(0deg, rgba(255,255,255,0.05) 30%, rgba(255,255,255,0.3) 100%); 
                }
            @media screen and (min-width: 1000px){
                box-sizing: border-box;
                width: fit-content;
                max-width: 200px;
                padding: 0 20px;
                padding-left: 40px;
                clip-path: polygon(15px 0%, calc(100% - 15px) 0%, 100% calc(50%), 100% calc(50% ), calc(100% - 15px) 100%, 15px 100%, 0% calc(50% ), 0% calc(50%));
                :before{
                    content: "";
                    position: absolute;
                    height: 2px;
                    width: 36px;
                    background: rgba(255,255,255,0.3);
                    top: 12px;
                    right: -14px;
                    transform: rotate(55deg);
                }
                :after{
                    content: "";
                    position: absolute;
                    height: 2px;
                    width: 36px;
                    background: rgba(255,255,255,0.3);
                    bottom: 12px;
                    right: -14px;
                    transform: rotate(126deg);
                }
            }
        }

    `}>
        <li css={{zIndex:1}}
            onClick={()=>{
            setOpen(false)
            newDeck()
            }}
        >
            <ImPlus css={{color:'green', marginRight:10}} 
            />new deck</li>
        <li onClick={()=>{
            setOpen(false)
            deleteDeck()
            }}><ImCross css={{color:'red', marginRight:10}}/>delete deck</li>
    </ul>
</div>
}
export  default DeckActions